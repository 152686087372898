// react
import React from "react";
import {Dropdown, Form} from "react-bootstrap";

// local
import {
	Client,
	CURRENT_MONTH,
	CURRENT_YEAR,
	getDaysInMonth,
	MdlItemCount,
	MdlResourceCount,
	System,
	webservice,
	system
} from "../types";

/**
 * LoginForm
 */

interface FormProps {
	system: System,
	client: Client,
	statId: string,
	statName: string,
	cancel: () => void,
	submit: () => void,
}
interface FormState {
	date: Date
	counts: MdlItemCount[]
}

export class AnalyticsForm extends React.Component<FormProps, FormState> {

	constructor(props: FormProps) {
		super(props);

		let date = new Date(CURRENT_YEAR, CURRENT_MONTH);
		this.state = {
			date,
			counts: []
		}

		this.onChangeDate = this.onChangeDate.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
		this.onFormCancel = this.onFormCancel.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	fetchStats = async (year: number, month: number) => {
		// setup
	}

	componentDidMount = async () => {
		// setup
		const {date} = this.state;
		const year = date.getFullYear();
		const month = date.getMonth() + 1;

		await this.fetchStats(year, month);
	}

	onChangeDate = async (eventKey: any) => {
		// setup
		const parts = eventKey.split('-');
		const date = new Date(parts[0], parts[1], 1, 1, 1, 1);

		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		this.setState({date});

		// Retrieve the new information.
		await this.fetchStats(year, month);
	}

	onChangeValue(item: MdlItemCount, event: any) {
		// setup
		const {counts} = this.state;

		// Check for needed updates. Note: we must modify the value here.
		let field = event.target;
		if (item.engagements !== field.value) {
			item.engagements = field.value;
			item.modified = true;
			field.classList.add('modified');
			this.setState({counts});
		}
	}

	onFormCancel = async (event: any) => {
		// setup
		this.props.submit();
	}

	onFormSubmit = async (event: any) => {
		// setup
		const {statId} = this.props;
		const {counts, date} = this.state;
		const year = date.getFullYear();
		const month = date.getMonth() + 1;

		const params = new FormData();
		params.append('wstoken', system.apiKey);
		params.append('wsfunction', 'mdl_add_activities');
		params.append('moodlewsrestformat', 'json');

		params.append('source', 'reports');
		params.append('year', year.toString());
		params.append('month', month.toString());

		// Find all the items that have been modified.
		let count = 0;
		for (let i = 0; i < counts.length; i++) {
			if (counts[i].modified) {
				params.append('activities[]', JSON.stringify({
					company_id: counts[i].company_id,
					activity_id: 0,
					activity_type: statId,
					activity_category: 'communication',
					activity_name: 'hope',
					activity_title: 'Hope & Norm',
					time: 60,
					source: 'reports',
					engagements: counts[i].engagements
				}));
				count++;
			}
		}

		// Update the stats.
		if (count) {
			await webservice.post('/webservice/rest/server.php', params);
		}

		this.props.cancel();
	}

	render() {
		// setup
		// render
		return (
			<div id="AnalyticsForm">
			</div>
		);
	}

	renderCount(stats: MdlItemCount[]) {
		return stats ?
			stats.map(s => {
				return <div className="form-field">
					<label className="field-lg-label" htmlFor="value">{s.company_name}</label>
					<Form.Control
						className="field-lg-value"
						type="text"
						defaultValue={s.engagements}
						onChange={(event: any) => this.onChangeValue(s, event)}
					/>
				</div>
			}) :
			null;
	}

	renderCounts(stats: MdlResourceCount, statId: string) {
		// setup
		let vCounts = this.renderCount(this.state.counts);
		return (<Form className="mt-3" onSubmit={() => this.onFormSubmit}>
			<Form.Group className="mt-1 form-field" controlId="text">
				{vCounts}
			</Form.Group>
		</Form>);

	}

	renderMonths() {
		// setup
		const {date} = this.state;
		const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
		let startDate = new Date(CURRENT_YEAR, 0, 1) ;
		const endDate = new Date(CURRENT_YEAR, CURRENT_MONTH, getDaysInMonth(CURRENT_YEAR, CURRENT_MONTH)-1);

		const dateId = date.toLocaleString('en-US', options);

		const eDates = [];
		while (startDate < endDate) {
			// setup
			const id = startDate.getFullYear() + '-' + startDate.getMonth();
			const name = startDate.toLocaleString('en-US', options);

			// render
			eDates.push(
				<Dropdown.Item id={id} key={id} eventKey={id}>
					{name}
				</Dropdown.Item>
			);

			// Increment the date.
			if (startDate.getMonth() === 11) {
				startDate = new Date(CURRENT_YEAR + 1, 0, 1);
			} else {
				startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
			}
		}

		return (
			<Dropdown onSelect={this.onChangeDate}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{dateId}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{eDates}
				</Dropdown.Menu>
			</Dropdown>
		);
	}

}

