// react
import React from "react";
import {withNavigation} from "../types";

/**
 * ForgotPassword
 */

interface PasswordProps {
}
interface PasswordState {
}

class ForgotPasswordComponent extends React.Component<PasswordProps, PasswordState> {
	render() {
		return <div>forgot-password</div>
	}
}

export const ForgotPassword = withNavigation(ForgotPasswordComponent);
