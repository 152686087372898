// react
import React from "react";
import {BeatLoader} from "react-spinners";

/**
 * Loading
 */

export const Loading = (props: any) => {

	// setup
	const className = props.className ? 'spinner ' + props.className: 'spinner';

	// render
	return (
		<div id="Loading" className={className}>
			<BeatLoader size={30} color={"#cccccc"} />
		</div>
	);

}
