// react
import React from "react";
import {IoBugOutline} from "react-icons/io5";
import {AiOutlineBarChart} from "react-icons/ai";
import {FaProjectDiagram} from "react-icons/fa";

// local
import {
	Client,
	User,
} from "../../types";
import {NavButton} from "../../components";
import {PopupIFrameForm} from "../../forms";

/**
 * UserSection
 */

interface SectionProps {
	user: User,
	client: Client
}
interface SectionState {
	showIssueForm: boolean,
	showFeatureForm: boolean
}

export class SupportSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);

		this.state = {
			showIssueForm: false,
			showFeatureForm: false
		}

		// Register the callbacks.
		this.onToggleIssueForm = this.onToggleIssueForm.bind(this);
		this.onToggleFeatureForm = this.onToggleFeatureForm.bind(this);
	}

	onToggleFeatureForm() {
		this.setState({showFeatureForm: !this.state.showFeatureForm});
	}

	onToggleIssueForm() {
		this.setState({showIssueForm: !this.state.showIssueForm});
	}

	render() {
		// setup

		// render
		return (
			<div>
				<div className='section'>
					<div className='section-header'>
						<AiOutlineBarChart className="section-icon" size={28}/>
						System Support
						<NavButton
							className='navigate-issue'
							text='Make a project request'
							icon={<FaProjectDiagram className="nav-icon" size={18}/>}
							onClick={this.onToggleFeatureForm}
						/>
						<NavButton
							className='navigate-issue'
							text='Report an issue'
							icon={<IoBugOutline className="nav-icon" size={18}/>}
							onClick={this.onToggleIssueForm}
						/>
					</div>

					<div className="row">
					</div>
					<hr/>

				</div>
			</div>
		)
	}

}

