// react
import React from "react";
import {ProgressBar, Table} from "react-bootstrap";

// local
import {CURRENT_MONTH, CURRENT_YEAR, LocationSummary} from "../../types";

/**
 * LocationStats
 */

interface LocationProps {
	summary: LocationSummary[]
}
interface LocationState {
}

export class LocationStats extends React.Component<LocationProps, LocationState> {

	render() {
		// setup
		const timeframe = {
			year: CURRENT_YEAR - 2000,
			month: CURRENT_MONTH - 1,
			year1: 0,
			month1: 0,
			year2: 0,
			month2: 0
		};
		if (timeframe.month < 0) {
			timeframe.month = 11;
			timeframe.year--;
		}
		timeframe.month1 = timeframe.month-1;
		timeframe.year1 = timeframe.year;
		if (timeframe.month1 < 0) {
			timeframe.month1 = 11;
			timeframe.year1--;
		}
		timeframe.month2 = timeframe.month1-1;
		timeframe.year2 = timeframe.year1;
		if (timeframe.month2 < 0) {
			timeframe.month2 = 11;
			timeframe.year2--;
		}

		// Construct the list.
		let stats = {
			users: 0,
			access: 0,
			month: 0,
			month1: 0,
			month2: 0
		};
		const head = this.renderHeader(timeframe);
		const body = this.renderBody(timeframe, stats);
		const footer = this.renderFooter(stats);

		// render
		return (
			<div id="LocationStats">
				<h4 className="title">Engagement by Location</h4>
				<Table id="LocationStats-Table" striped bordered responsive>
					<thead className="locations">{head}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		);
	}

	renderBody(timeframe: any, stats: any) {
		// setup
		const {summary} = this.props;

		// Render the table body.
		let body = [];
		for (let i = 0; i < summary.length; i++) {
			// setup
			const item = summary[i];
			const key = 'location-'+item.id;
			stats.users += item.users;
			stats.month2 += item.engagements[timeframe.month2];
			stats.month1 += item.engagements[timeframe.month1];
			stats.month += item.engagements[timeframe.month];
			stats.access += item.access;

			const access = Math.ceil(item.access * 100 / item.users);
			const mo3 = item.engagements[timeframe.month2];
			const mo2 = item.engagements[timeframe.month1];
			const mo1 = item.engagements[timeframe.month];

			// render
			body.push(<tr key={key}>
				<td className="index">{i+1}</td>
				<td className="description">{item.name}</td>
				<td className="value">{item.users.toLocaleString()}</td>
				<td className="value">{mo3}</td>
				<td className="value">{mo2}</td>
				<td className="value">{mo1}</td>
				<td className="value">
					<ProgressBar now={access} label={access + '%'} />
				</td>
			</tr>);
		}

		return body;
	}

	renderFooter(stats: any) {
		// setup
		const access = Math.ceil(stats.access * 100 / stats.users);

		// render
		return [
			<tr key='location-footer'>
				<td className="index"></td>
				<td className="total">total</td>
				<td className="value">{stats.users.toLocaleString()}</td>
				<td className="value">{stats.month2}</td>
				<td className="value">{stats.month1}</td>
				<td className="value">{stats.month}</td>
				<td className="value">{access + '%'}</td>
			</tr>
		]
	}

	renderHeader(timeframe: any) {
		return [
			<tr key='location-header'>
				<th className="index">#</th>
				<th className="description">Location</th>
				<th className="value">users</th>
				<th className="value">{`${timeframe.month2+1}/${timeframe.year2}`}</th>
				<th className="value">{`${timeframe.month1+1}/${timeframe.year1}`}</th>
				<th className="value">{`${timeframe.month+1}/${timeframe.year}`}</th>
				<th className="value">{`3mo%`}</th>
			</tr>
		];
	}

}

