// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {
	Client,
	CouponInfo, SalesInfo, timestampToStrDate, TOOLTIP_DELAY
} from "../../types";
import {Tooltip} from "react-tooltip";

/**
 * Coupons Summary
 */

interface SummaryProps {
	client: Client
	coupons: SalesInfo[],
	couponId: string,
	onSelectItem: (itemId: string) => void
}
interface SummaryState {
}

export class CouponsSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);
	}

	render() {
		// Construct the list.
		let total = {
			qty: 0,
			total: 0
		};
		const showCategories = true;

		const header = this.renderHeader();
		const body = this.renderBody(total);
		const footer = this.renderFooter(total);

		// render
		return (
			<div id="CouponsSummary">
				<Table id="CouponsSummary-Table" striped bordered responsive>
					<Tooltip id="lct-coupons-tooltip"/>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					{footer}
				</Table>
			</div>
		)
	}

	renderBody(total: any) {
		// setup
		const {coupons, couponId, onSelectItem} = this.props;
		const showCategories = true;

		// Check for an empty list.
		if (!coupons.length) {
			return <tr key='coupon-none'>
				<td colSpan={6}>No coupons</td>
			</tr>
		}

		// render
		let category = "";
		const body = [];
		for (let i = 0; i < coupons.length; i++) {
			// item
			const item = coupons[i];

			// Check the category section.
			if (showCategories && item.company_name !== category) {
				category = item.company_name;
				body.push(<tr key={'coupon-category-'+item.id}>
					<td className="location" colSpan={6}>{category}</td>
				</tr>);
			}

			// coupon information
			const created = timestampToStrDate(item.created, false);
			const selected = (item.coupon === couponId) ? 'selectable selected' : 'selectable';
			const price = item.total / item.qty;
			total.qty += item.qty;
			total.total += item.total;

			// render
			body.push(
				<tr
					key={'coupon-'+i}
					className={selected}
					onClick={() => onSelectItem(item.coupon)}
					data-tooltip-id="lct-coupons-tooltip"
					data-tooltip-content={item.order_item_name}
					data-tooltip-place='top-start'
					data-tooltip-delay-show={TOOLTIP_DELAY}
				>
					<td className='id'>{item.order_id}</td>
					<td className='description'>{item.order_item_name}</td>
					<td className='date'>{created}</td>
					<td className='value'>{price.toFixed(2)}</td>
					<td className='value'>{item.qty}</td>
					<td className='value'>{item.total.toFixed(2)}</td>
				</tr>
			);
		}
		return body;
	}

	renderFooter(total: any) {
		// setup
		const fCurrency = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 2
		});

		// return
		return <tr key='coupon-footer'>
			<td className='id'/>
			<td className='description'/>
			<td className='date'/>
			<td className='value'/>
			<td className='value'>{total.qty.toLocaleString()}</td>
			<td className='value'>{fCurrency.format(total.total)}</td>
		</tr>;
	}

	renderHeader() {
		return <tr key='coupon-header'>
			<th className="value">ID</th>
			<th className="description">Description</th>
			<th className="value">Date</th>
			<th className="value">Price</th>
			<th className="value">Qty</th>
			<th className="value">Total</th>
		</tr>
	}

}
