// react
import React from "react";
import {applySnapshot, Instance, types} from "mobx-state-tree"

// local

/**
 * SearchFilter
 */

export interface SearchFilterModel {
	first_name: string | null,
	last_name: string | null,
	email: string | null,
	phone: string | null,
	postcode: string | null,
	order_id: number,
	order_title: string | null,
	course_id: number,
	course_title: string | null,
	birth: string | null,
	license: string | null
}
export const SearchFilterInfo = types.model("SearchFilterInfo", {
	// core
	first_name: types.maybeNull(types.string),
	last_name: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	postcode: types.maybeNull(types.string),
	// order
	order_id: types.number,
	order_title: types.maybeNull(types.string),
	course_id: types.number,
	course_title: types.maybeNull(types.string),
	// other
	birth: types.maybeNull(types.string),
	license: types.maybeNull(types.string)
}).actions(self => {

	function clear() {
		// Reset the filter information.
		self.first_name = null;
		self.last_name = null;
		self.email = null;
		self.phone = null;
		self.postcode = null;
		self.order_id = 0;
		self.order_title = null;
		self.course_id = 0;
		self.course_title = null;
		self.birth = null;
		self.license = null;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	function set(filter: SearchFilterModel) {
		// setup
		self.first_name = filter.first_name;
		self.last_name = filter.last_name;
		self.email = filter.email;
		self.phone = filter.phone;
		self.postcode = filter.postcode;
		self.order_id = filter.order_id;
		self.order_title = filter.order_title;
		self.course_id = filter.course_id;
		self.course_title = filter.course_title;
		self.birth = filter.birth;
		self.license = filter.license;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	return {
		clear,
		set
	}
}).views(self => ({

	get(): SearchFilterModel {
		return {
			first_name: self.first_name,
			last_name: self.last_name,
			email: self.email,
			phone: self.phone,
			postcode: self.postcode,
			order_id: self.order_id,
			order_title: self.order_title,
			course_id: self.course_id,
			course_title: self.course_title,
			birth: self.birth,
			license: self.license

		}
	},

	getFilter(): string {
		// Determine the filter settings.
		let filters: string[] = [];
		if (self.first_name) {
			filters.push(self.first_name);
		}
		if (self.last_name) {
			filters.push(self.last_name);
		}
		if (self.email) {
			filters.push(self.email);
		}
		if (self.first_name) {
			filters.push(self.first_name);
		}
		if (self.phone) {
			filters.push(self.phone);
		}
		if (self.postcode) {
			filters.push(self.postcode);
		}
		if (self.order_id) {
			filters.push(self.order_id.toString());
		}
		if (self.course_id) {
			filters.push(self.course_id.toString());
		}
		if (self.birth) {
			filters.push(self.birth);
		}
		if (self.license) {
			filters.push(self.license);
		}
		return filters.join(' ');
	}

}));
export type SearchFilter = Instance<typeof SearchFilterInfo>;

/**
 * SortActivityFilter
 */

export interface SortActivityFilterModel {
	id: string,
	date: string,
	activity_category: string,
	activity_title: string,
	total: string,
	started: string,
	started_percent: string,
	completed: string,
	completed_percent: string
	// state
	by: string,
	direction: string
}
export const SortActivityFilterInfo = types.model("SortCourseFilterInfo", {
	// core
	id: types.string,
	date: types.string,
	activity_category: types.string,
	activity_title: types.string,
	total: types.string,
	started: types.string,
	started_percent: types.string,
	completed: types.string,
	completed_percent: types.string,
	// other
	by: types.string,
	direction: types.string
}).actions(self => {

	function clear(sort: SortActivityFilterModel) {
		// Reset the filter information.
		self.id = sort.id;
		self.date = sort.date;
		self.activity_category = sort.activity_category;
		self.activity_title = sort.activity_title;
		self.started = sort.started;
		self.started_percent = sort.started_percent;
		self.completed = sort.completed;
		self.completed_percent = sort.completed_percent;
		self.total = sort.total;
		self.by = sort.by;
		self.direction = sort.direction;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	function set(sortBy: string, sortDirection: string) {
		// Update the sort by and direction.
		if (self.by !== sortBy) {
			self.direction = sortDirection;
		} else if (sortBy === 'id') {
			self.direction = self.id = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'date') {
			self.direction = self.date = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'activity_category') {
			self.direction = self.activity_category = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'activity_title') {
			self.direction = self.activity_title = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'started') {
			self.direction = self.started = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'started_percent') {
			self.direction = self.started_percent = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'completed') {
			self.direction = self.completed = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'completed_percent') {
			self.direction = self.completed_percent = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'total') {
			self.direction = self.total = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		}
		self.by = sortBy;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	return {
		clear,
		set
	}
}).views(self => ({

	get(): SortActivityFilterModel {
		return {
			id: self.id,
			date: self.date,
			activity_category: self.activity_category,
			activity_title: self.activity_title,
			started: self.started,
			started_percent: self.started,
			completed: self.completed,
			completed_percent: self.completed,
			total: self.total,
			// other
			by: self.by,
			direction: self.direction
		}
	},

	getFilter(): string {
		return self.by + ' ' + self.direction;
	}

}));
export type SortActivityFilter = Instance<typeof SortActivityFilterInfo>;

/**
 * SortCourseFilter
 */

export interface SortCourseFilterModel {
	id: string,
	company_name: string,
	user_last_name: string,
	course_title: string,
	enrolled: string,
	started: string,
	progress: string,
	completed: string,
	score: string,
	// state
	by: string,
	direction: string
}
export const SortCourseFilterInfo = types.model("SortCourseFilterInfo", {
	// core
	id: types.string,
	company_name: types.string,
	user_last_name: types.string,
	course_title: types.string,
	enrolled: types.string,
	started: types.string,
	progress: types.string,
	completed: types.string,
	score: types.string,
	// other
	by: types.string,
	direction: types.string
}).actions(self => {

	function clear(sort: SortCourseFilterModel) {
		// Reset the filter information.
		self.id = sort.id;
		self.company_name = sort.company_name;
		self.user_last_name = sort.user_last_name;
		self.course_title = sort.course_title;
		self.enrolled = sort.enrolled;
		self.started = sort.started;
		self.completed = sort.completed;
		self.score = sort.score;
		self.by = sort.by;
		self.direction = sort.direction;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	function set(sortBy: string, sortDirection: string) {
		// Update the sort by and direction.
		if (self.by !== sortBy) {
			self.direction = sortDirection;
		} else if (sortBy === 'id') {
			self.direction = self.id = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'company_name') {
			self.direction = self.id = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'user_last_name') {
			self.direction = self.user_last_name = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'course_title') {
			self.direction = self.course_title = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'enrolled') {
			self.direction = self.enrolled = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'started') {
			self.direction = self.started = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'progress') {
			self.direction = self.progress = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'completed') {
			self.direction = self.completed = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'score') {
			self.direction = self.score = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		}
		self.by = sortBy;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	return {
		clear,
		set
	}
}).views(self => ({

	get(): SortCourseFilterModel {
		return {
			id: self.id,
			company_name: self.company_name,
			user_last_name: self.user_last_name,
			course_title: self.course_title,
			enrolled: self.enrolled,
			started: self.started,
			progress: self.progress,
			completed: self.completed,
			score: self.score,
			// other
			by: self.by,
			direction: self.direction
		}
	},

	getFilter(): string {
		return self.by + ' ' + self.direction;
	}

}));
export type SortCourseFilter = Instance<typeof SortCourseFilterInfo>;

/**
 * SortSalesFilter
 */

export interface SortSalesFilterModel {
	id: string,
	company_name: string,
	user_last_name: string,
	order_item_name: string,
	created: string,
	// state
	by: string,
	direction: string
}
export const SortSalesFilterInfo = types.model("SortSalesFilterInfo", {
	// core
	id: types.string,
	company_name: types.string,
	user_last_name: types.string,
	order_item_name: types.string,
	created: types.string,
	// other
	by: types.string,
	direction: types.string
}).actions(self => {

	function clear(sort: SortSalesFilterModel) {
		// Reset the filter information.
		self.id = sort.id;
		self.company_name = sort.company_name;
		self.user_last_name = sort.user_last_name;
		self.order_item_name = sort.order_item_name;
		self.created = sort.created;
		self.by = sort.by;
		self.direction = sort.direction;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	function set(sortBy: string, sortDirection: string) {
		// Update the sort by and direction.
		if (self.by !== sortBy) {
			self.direction = sortDirection;
		} else if (sortBy === 'id') {
			self.direction = self.id = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'company_name') {
			self.direction = self.company_name = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'user_last_name') {
			self.direction = self.user_last_name = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'order_item_name') {
			self.direction = self.order_item_name = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		} else if (sortBy === 'created') {
			self.direction = self.created = (sortDirection == 'sort-asc') ? 'sort-desc' : 'sort-asc';
		}
		self.by = sortBy;

		// Apply the changes.
		applySnapshot(self, {...self});
	}

	return {
		clear,
		set
	}
}).views(self => ({

	get(): SortSalesFilterModel {
		return {
			id: self.id,
			company_name: self.company_name,
			user_last_name: self.user_last_name,
			order_item_name: self.order_item_name,
			created: self.created,
			// other
			by: self.by,
			direction: self.direction
		}
	},

	getFilter(): string {
		return self.by + ' ' + self.direction;
	}

}));
export type SortSalesFilter = Instance<typeof SortSalesFilterInfo>;
