// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {PollResponse, TOOLTIP_DELAY} from "../../types";
import {Tooltip} from "react-tooltip";

/**
 * CPOTMSurvey
 */

interface CPOTMProps {
	year: number,
	month: number,
	responses: PollResponse[]
}

export const CPOTMSurvey = (props: CPOTMProps) => {
	// setup
	const {responses, year, month} = props;
	const colSpan = 3;

	function addCPOTMHeader(item: PollResponse, data: any, index: number) {
		return (<tr key='survey-response-header'>
			<th className='index'>#</th>
			<th className='label'>Who</th>
			<th className='description'>Why</th>
		</tr>);
	}

	function addCPOTMItem(item: PollResponse, data: any, index: number) {
		// DATA => who, why
		const key = 'result-'+index;
		return (<tr key={key}>
			<td className='index'>{item.total}</td>
			<td className='label'>{data.who}</td>
			<td className='multiline-description'>{data.why}</td>
		</tr>);
	}

	let header = [];
	let body = [];
	let company = null;
	let subcategory = null;
	for (let i = 0; i < responses.length; i++) {
		// setup
		const item = responses[i];
		const data = JSON.parse(item.response);

		// render
		if (item.company !== company) {
			company = item.company;
			subcategory = null;
			body.push(<tr key={'survey-response-company-'+i}>
				<td className="location" colSpan={colSpan}>{company}</td>
			</tr>);
		}
		if (item.question !== subcategory) {
			subcategory = item.question;
			body.push(<tr
				key={'survey-response-'+i}
				className='subcategory'
			>
				<td className="subcategory" colSpan={colSpan}>{subcategory}</td>
			</tr>);
		}

		if (!header.length) {
			header.push(addCPOTMHeader(item, data, i));
		}
		body.push(addCPOTMItem(item, data, i));
	}

	return (
		<div>
			<h4 className="title">Response Details ({month+'/'+(year-2000)})</h4>
			<Table id="Responses-Table" striped bordered responsive>
				<thead>{header}</thead>
				<tbody>{body}</tbody>
			</Table>
		</div>
	);
}

/**
 * CHECKINSurvey
 */

interface CHECKINProps {
	year: number,
	month: number,
	responses: PollResponse[]
}

export const CHECKINSurvey = (props: CHECKINProps) => {
	// setup
	const PROMOTER = 9;
	const DETRACTOR = 6;

	const {responses, year, month} = props;

	const summary = responses[0];
	const summaryData = JSON.parse(summary.response);
	const start = 3;
	const cols = 10;

	let gCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	let gTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	function addFooter(gTotal: number[], gCount: number[]) {
		let td = [];
		td.push(<td>{'All'}</td>);
		for (let i = 0; i < cols; i++) {
			if (gTotal[i]) {
				const value = (gTotal[i] / gCount[i]).toFixed(1);
				td.push(<td>{value}</td>)
			} else {
				td.push(<td>N/A</td>);
			}
		}
		return <tr>{td}</tr>;
	}

	function addQuestions(data: any) {
		let questions = [];
		questions.push(<div className="label bold">Questions: (responses below are 0-10 averages)</div>);
		for (let i = 0; i < cols; i++) {
			questions.push(<div className="label">{`${data[i+start].id}. ${data[i+start].question}`}</div>);
		}
		return <div className="row questions">
			{questions}
		</div>;
	}

	function addHeader(data: any) {
		let th = [];
		th.push(<th className="subcategory">Location</th>);
		for (let i = 0; i < cols; i++) {
			th.push(<th>{'Q'+data[i+start].id}</th>);
		}
		th.push(<th>NPS</th>);

		return <tr>
			{th}
		</tr>;
	}

	function addAverages(company: string, gTotal: number[], gCount: number[], responses: PollResponse[], index: number) {
		// DATA => questions/responses
		const key = 'result-'+index;
		let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		let total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

		// NPS scores = https://www.surveymonkey.com/mp/net-promoter-score-calculation/
		//      NPS = (Number of Promoters — Number of Detractors) / (Number of Respondents) x 100
		//      0–6 = Detractors—unhappy customers who can hurt your brand through negative word-of-mouth
		//      7–8 = Passives—satisfied but indifferent customers who could be swayed by the competition
		//      9–10 = Promoters—loyal customers who will keep buying and referring others
		let promoters = 0;
		let detractors = 0
		let respondents = 0;

		// Question responses.
		for (let i = 0; i < responses.length; i++) {
			const response = responses[i];
			if (response.company === company) {
				const data = JSON.parse(response.response);
				for (let i = 0; i < cols; i++) {
					const item = data[i+start];
					if (item.type === 'multichoice' && item.response >= 0) {
						total[i] += item.response;
						if (item.response < DETRACTOR) {
							detractors++;
						} else if (item.response >= PROMOTER) {
							promoters++;
						}
						respondents++;
						count[i]++;
					}
				}
			}
		}

		// Question values.
		let td = [];
		td.push(<td className="subcategory">{company}</td>);
		for (let i = 0; i < cols; i++) {
			// columns
			if (total[i]) {
				const value = total[i] / count[i];
				const className = (value >= PROMOTER) ? 'promoter' : ((value < DETRACTOR) ? 'detractor' : '');
				gTotal[i] += value;
				gCount[i]++;
				td.push(<td className={className}>{value.toFixed(1)}</td>);
			} else {
				td.push(<td>N/A</td>);
			}
		}

		// NPS score
		const value = Math.ceil((promoters - detractors) * 100 / respondents);
		td.push(<td>{value}</td>);

		return <tr key={key}>{td}</tr>;
	}

	// header
	let header = [
		addHeader(summaryData)
	];

	// body
	let body: any = [];
	let company = null;
	for (let i = 0; i < responses.length; i++) {
		// setup
		const item = responses[i];

		// render
		if (item.company !== company) {
			company = item.company;
			const row = addAverages(company, gTotals, gCounts, responses, i);
			body.push(row);
		}
	}

	// footer
	let footer = [
		addFooter(gTotals, gCounts)
	];

	return (
		<div>
			<h4 className="title">NPS Response Details ({month+'/'+(year-2000)})</h4>
			{addQuestions(summaryData)}
			<Table id="Responses-Table" striped bordered>
				<thead>{header}</thead>
				<tbody>{body}</tbody>
				<tfoot>{footer}</tfoot>
			</Table>
		</div>
	);
}

/**
 * Survey Responses
 */

interface SurveyProps {
	survey: string,
	year: number,
	month: number,
	responses: PollResponse[],
	className?: string
}

export const SurveyResponses = (props: SurveyProps) => {

	// setup
	const {responses, year, month, className} = props;
	if (!responses.length) {
		return null;
	}

	// render
	const data = JSON.parse(responses[0].response);

	// render
	return (
		<div id="SurveyResponses" className={className ? className : ''}>
			{data.who ?
				<CPOTMSurvey responses={responses} year={year} month={month}/> :
				<CHECKINSurvey responses={responses} year={year} month={month}/>}
		</div>
	);

}
