// react
import React from "react";
import {ProgressBar, Table} from "react-bootstrap";

// local
import {ActivitySummary, Client} from "../../types";

/**
 * Course Views
 */

interface ViewsProps {
	client: Client,
	clientName: string,
	activityId: string
}
interface ViewState {
	loaded: boolean,
	clientName: string,
	activities: ActivitySummary[],
	infoType: string,
	showCategories: boolean
}

export class CoursesViews extends React.Component<ViewsProps, ViewState> {

	constructor(props: ViewsProps) {
		super(props);
		const {clientName} = props;

		this.state = {
			loaded: false,
			clientName: clientName,
			activities: [],
			infoType: 'Engagement',
			showCategories: true
		}
	}

	componentDidMount = async () => {
		// setup
		this.fetchAnalytics(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName} = this.state;

		// See if we need changes.
		if (pState.clientName !== this.props.clientName) {
			const showCategories = (client.departments.length > 1);
			await this.setState({clientName: this.props.clientName, showCategories, loaded: false});
		}
		if (pState.clientName !== clientName) {
			await this.fetchAnalytics(true);
		}
	}

	fetchAnalytics = async (force: boolean) => {
		// setup
		const {client} = this.props;

		// Update the summary information.
		const infoType = client.getCourseType();
		const activities = client.getCourseBreakdown();
		this.setState({loaded: true, activities, infoType});
	}

	render() {
		// setup
		const {activityId} = this.props;
		const {activities, showCategories, infoType} = this.state;

		// Construct the list.
		let total = {
			enrolled: 0,
			started: 0,
			completed: 0
		};
		let header, body, footer;
		if (infoType === 'Engagement') {
			header = this.renderEngagementHeader(!!activityId);
			body = activityId ?
				this.renderEngagementByID(activities, activityId, total) :
				this.renderEngagementBody(activities, showCategories, total)
			;
			footer = this.renderEngagementFooter(total);
		} else {
			header = this.renderActivityHeader(!!activityId);
			body = activityId ?
				this.renderActivityByID(activities, activityId, total) :
				this.renderActivityBody(activities, showCategories, total);
			footer = this.renderActivityFooter(total);
		}

		// render
		return (
			<div id="CourseViews">
				<h4 className="title">Viewing Details</h4>
				<Table id="CourseViews-Table" striped bordered responsive hover>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		);
	}

	renderActivityBody(activities: ActivitySummary[], showCategories: boolean, total: any) {
		// setup
		let category = null;
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			const key = `${item.activity_type}-${i}`;
			const sPercent = item.started_percent.toString() + '%';
			const cPercent = item.completed_percent.toString() + '%';

			total.enrolled += item.total;
			total.started += item.started;
			total.completed += item.completed;

			// render
			if (showCategories && item.activity_category !== category) {
				category = item.activity_category;
				body.push(<tr key={'course-views-category-'+i}>
					<td className="location" colSpan={6} >{category}</td>
				</tr>);
			}
			body.push(<tr key={key}>
				<td className="description">{item.activity_title}</td>
				<td className="value">{item.total}</td>
				<td className="value">{item.started}</td>
				<td className="percent started">
					<ProgressBar now={item.started_percent} label={sPercent} />
				</td>
				<td className="value">{item.completed}</td>
				<td className="percent">
					<ProgressBar now={item.completed_percent} label={cPercent} />
				</td>
			</tr>);
		}
		return body;
	}

	renderActivityByID(activities: ActivitySummary[], activityId: string, total: any) {
		// setup
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			if (item.activity_title !== activityId) {
				continue;
			}

			const key = `${item.activity_type}-${i}`;
			const sPercent = item.started_percent.toString() + '%';
			const cPercent = item.completed_percent.toString() + '%';

			total.enrolled += item.total;
			total.started += item.started;
			total.completed += item.completed;

			// render
			body.push(<tr key={key}>
				<td className="description">{item.activity_category}</td>
				<td className="value">{item.total}</td>
				<td className="value">{item.started}</td>
				<td className="percent started">
					<ProgressBar now={item.started_percent} label={sPercent} />
				</td>
				<td className="value">{item.completed}</td>
				<td className="percent">
					<ProgressBar now={item.completed_percent} label={cPercent} />
				</td>
			</tr>);
		}
		return body;
	}

	renderActivityFooter(total: any) {
		// setup
		const pStarted = total.enrolled ? Math.ceil(total.started * 100 / total.enrolled) + '%' : '0%';
		const pCompleted = total.enrolled ? Math.ceil(total.completed * 100 / total.enrolled) + '%' : '0%';

		// render
		return [
			<tr key='courses-views-footer'>
				<th className="description"></th>
				<th className="value">{total.enrolled.toLocaleString()}</th>
				<th className="value">{total.started.toLocaleString()}</th>
				<th className="value">{pStarted}</th>
				<th className="value">{total.completed.toLocaleString()}</th>
				<th className="value">{pCompleted}</th>
			</tr>
		];
	}

	renderActivityHeader(activity: boolean) {
		// render
		return [
			<tr key='courses-views-header'>
				<th className="description">{activity ? 'Location' : 'Title'}</th>
				<th className="value">enrolled</th>
				<th className="value">started</th>
				<th className="percent">%</th>
				<th className="value">finished</th>
				<th className="percent">%</th>
			</tr>
		];
	}

	renderEngagementBody(activities: ActivitySummary[], showCategories: boolean, total: any) {
		// setup
		let category = null;
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			const key = `${item.activity_type}-${i}`;

			total.enrolled += item.total;
			total.started += item.started;

			// render
			if (showCategories && item.activity_category !== category) {
				category = item.activity_category;
				body.push(<tr key={'course-views-category-'+i}>
					<td className="location" colSpan={3} >{category}</td>
				</tr>);
			}
			body.push(<tr key={key}>
				<td className="description">{item.activity_title}</td>
				<td className="value">{item.started}</td>
				<td className="value">{item.total}</td>
			</tr>);
		}
		return body;
	}

	renderEngagementByID(activities: ActivitySummary[], activityId: string, total: any) {
		// setup
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			if (item.activity_title !== activityId) {
				continue;
			}
			const key = `${item.activity_type}-${i}`;

			total.enrolled += item.total;
			total.started += item.started;
			total.completed += item.completed;

			// render
			body.push(<tr id={key}>
				<td className="description">{item.activity_category}</td>
				<td className="value">{item.started}</td>
				<td className="value">{item.total}</td>
			</tr>);
		}
		return body;
	}

	renderEngagementFooter(total: any) {
		return [
			<tr key='courses-views-footer'>
				<th className="description"></th>
				<th className="value">{total.started.toLocaleString()}</th>
				<th className="value">{total.enrolled.toLocaleString()}</th>
			</tr>
		];
	}

	renderEngagementHeader(activity: boolean) {
		return [
			<tr key='courses-views-header'>
				<th className="description">{activity ? 'Location' : 'Title'}</th>
				<th className="value">Users</th>
				<th className="value">Views</th>
			</tr>
		];
	}

	renderIdBody(activities: ActivitySummary[], activityId: string, total: any) {
		// setup
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			if (item.activity_title !== activityId) {
				continue;
			}
			const key = `${item.activity_type}-${i}`;
			const sPercent = item.started_percent.toString() + '%';
			const cPercent = item.completed_percent.toString() + '%';

			total.enrolled += item.total;
			total.started += item.started;
			total.completed += item.completed;

			// render
			body.push(<tr id={key}>
				<td className="description">{item.activity_category}</td>
				<td className="value">{item.total}</td>
				<td className="value">{item.started}</td>
				<td className="percent started">
					<ProgressBar now={item.started_percent} label={sPercent} />
				</td>
				<td className="value">{item.completed}</td>
				<td className="percent">
					<ProgressBar now={item.completed_percent} label={cPercent} />
				</td>
			</tr>);
		}
		return body;
	}

}

