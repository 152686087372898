// react
import React from "react";
import {Button, Form} from "react-bootstrap";

// local
import {FreshdeskTicket, FreshdeskUser} from "../types";
import {frApi} from "../App";
import {Loading} from "../components";

/**
 * Info
 */

interface InfoProps {
	ticket: FreshdeskTicket,
	close: () => void,
}
interface InfoState {
	loaded: boolean,
	user: FreshdeskUser | null
}

export class SupportFreshdeskForm extends React.Component<InfoProps, InfoState> {

	constructor(props: InfoProps) {
		super(props);

		this.state = {
			loaded: false,
			user: null
		}

		// Register the callbacks.
		this.onFormClose = this.onFormClose.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {ticket} = this.props;
		const that = this;

		frApi.getContact(ticket.requester_id, function (err: any, data: any, extra: any) {
			let user;
			if (data) {
				user = {
					id: data.id,
					first_name: data.first_name,
					last_name: data.last_name,
					email: data.email ? data.email : '',
					phone: data.phone ? data.phone : ''
				};
			} else {
				user = {
					id: ticket.requester_id,
					first_name: '',
					last_name: '',
					email: '',
					phone: ''
				}
			}
			that.setState({user, loaded: true});
		});
	}

	onFormClose = async (event: any) => {
		// setup
		event.preventDefault(); // prevent page from reloading
		this.props.close();
	}

	render() {
		// render
		return (
			<div id="SupportForm">
				<Form className="form-popup">

					{this.renderFormHeader('Support Information')}
					{this.renderFormBody()}
					{this.renderFormFooter()}

				</Form>
			</div>
		);
	}

	renderFormBody() {
		// setup
		const {ticket} = this.props;
		const {user, loaded} = this.state;
		const body = document.getElementById('ReportsPage-body');
		const style = {"maxHeight": body ? body.clientHeight - 160 : 600};

		// render
		if (!loaded || !user) {
			return <Loading/>
		}
		return (
			<div className="form-body scroll-y" style={style}>

				<div className='form-block'>
					<div className='form-column'>
						<Form.Group controlId="first_name" className="form-field">
							<Form.Label className="field-md-label">First Name:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={user.first_name}
							/>
						</Form.Group>
						<Form.Group controlId="last_name" className="form-field">
							<Form.Label className="field-md-label">Last Name:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={user.last_name}
							/>
						</Form.Group>
					</div>
					<div className='form-column'>
						<Form.Group controlId="email" className="form-field">
							<Form.Label className="field-md-label">Email:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={user.email}
							/>
						</Form.Group>
						<Form.Group controlId="phone" className="form-field">
							<Form.Label className="field-md-label">Phone:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={user.phone}
							/>
						</Form.Group>
					</div>
				</div>

				<Form.Group controlId="subject" className="form-field">
					<Form.Label className="field-md-label">Subject:</Form.Label>
					<Form.Control
						className="field-md-value form-control" as="textarea"
						defaultValue={ticket.subject}
						rows={3}
					/>
				</Form.Group>
				<Form.Group controlId="description" className="form-field">
					<Form.Label className="field-md-label">Description:</Form.Label>
					<Form.Control
						className="field-md-value form-control1 form-multiline" as="textarea"
						defaultValue={ticket.description}
						rows={6}
					/>
				</Form.Group>

			</div>
		)
	}

	renderFormFooter() {
		// render
		return (
			<div className="form-footer">
			</div>
		)
	}

	renderFormHeader(title: string) {
		return (
			<div className="form-header">
				{title}
				<Button id='ClosePopup' variant="forgot" className="btn form-btn" size="sm" onClick={this.onFormClose}>
					X
				</Button>
			</div>
		)
	}
}

