// react
import React from "react";
import {Button, Form} from "react-bootstrap";

// local

/**
 * Info
 */

interface PopupProps {
	id: string,
	title: string,
	src: string,
	onPopupClose: () => void
}
interface PopupState {
}

export class PopupIFrameForm extends React.Component<PopupProps, PopupState> {

	render() {
		// render
		return (
			<div id={this.props.id}>
				<Form className="form-popup">
					{this.renderFormHeader()}
					{this.renderFormBody()}
				</Form>
			</div>
		);
	}

	renderFormBody() {
		// render
		return (
			<div className='form-body'>
				<iframe className='iframe-popup' src={this.props.src}/>
			</div>
		)
	}

	renderFormFooter() {
		// render
		return (
			<div className="form-footer">
			</div>
		)
	}

	renderFormHeader() {
		return (
			<div className="form-header">
				{this.props.title}
				<Button id='ClosePopup' variant="forgot" className="btn form-btn" size="sm" onClick={this.props.onPopupClose}>
					X
				</Button>
			</div>
		)
	}
}

