// react
import React from "react";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";
import {AiOutlineBarChart} from "react-icons/ai";

// local
import {ActivitySummary, Client, system, System, User, UTC_DATE_FORMAT} from "../../types";
import {AnalyticsForm} from "../../forms";
import {AnnualEngagements} from "./AnnualEngagements"
import {CareUtilization} from "./CareUtilization"
import {SummaryStats} from "./SummaryStats";
import {FiSettings, FiUsers} from "react-icons/fi";
import {Loading, NavButton, NavSearch, TimeframeDropdown} from "../../components";
import moment from "moment";
import {Tooltip} from "react-tooltip";

/**
 * CourseSection
 */

interface SectionProps {
	user: User,
	client: Client,
	navigate: NavigateFunction,
	system: System
}
interface SectionState {
	loaded: boolean,
	clientName: string
	statId: string,
	statName: string,
	chartId: string,
	chartName: string,
	ctlMonths: string[],
	annualStats: ActivitySummary[],
	monthlyStats: ActivitySummary[],
	startDate: string,
	endDate: string
}

export class EngagementSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		const {client} = props;

		// timeframes
		const ctlMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec' ];
		const startDate = moment().subtract(1, 'year').startOf('month').format(UTC_DATE_FORMAT);
		const endDate = moment().subtract(1, 'month').endOf('month').format(UTC_DATE_FORMAT);

		this.state = {
			loaded: false,
			clientName: client.name,
			statId: '',
			statName: '',
			chartId: 'utilization',
			chartName: 'Care Center Utilization',
			ctlMonths,
			annualStats: [],
			monthlyStats: [],
			startDate: startDate,
			endDate: endDate
		}

		// Register the callbacks.
		this.onCancelAnalytics = this.onCancelAnalytics.bind(this);
		this.onEditAnalytics = this.onEditAnalytics.bind(this);
		this.onGenerateReport = this.onGenerateReport.bind(this);
		this.onNavigateEngagements = this.onNavigateEngagements.bind(this);
		this.onSelectChart = this.onSelectChart.bind(this);
		this.onSubmitAnalytics = this.onSubmitAnalytics.bind(this);
		this.onSubmitTimeframe = this.onSubmitTimeframe.bind(this);
	}

	componentDidMount = async () => {
		// setup
		this.fetchAnalytics(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName, startDate, endDate} = this.state;

		// See if we need changes.
		if (pState.clientName !== client.name) {
			await this.setState({clientName: client.name});
		}
		if (pState.clientName !== clientName ||
			pState.startDate !== startDate || pState.endDate !== endDate) {
			await this.fetchAnalytics(true);
		}
	}

	fetchAnalytics = async (force: boolean) => {
		// setup
		const {client} = this.props;
		const {chartId} = this.state;

		// Update the summary information.
		const annualStats = client.getAnnualTotals();
		const monthlyStats = client.getMonthlyTotals(chartId);
		this.setState({loaded: true, annualStats, monthlyStats});
	}

	onCancelAnalytics() {
		this.setState({statId: "", statName: ""});
	}

	onEditAnalytics(chartId: string, chartName: string) {
		this.setState({statId: chartId, statName: chartName});
	}

	onGenerateReport = async () => {
		// setup
		const {client} = this.props;
		const {startDate, endDate} = this.state;

		// Generate the sales report.
		await client.generateReport(client.name, 'engagements', startDate, endDate);
	}

	onNavigateEngagements() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/engagements");
	}

	onSelectChart(chartId: string, chartName: string) {
		// setup
		const {client} = this.props;

		// Update the monthly chart.
		const monthlyStats = client.getMonthlyTotals(chartId);
		this.setState({chartId, chartName, monthlyStats});
	}

	onSubmitAnalytics() {
		this.setState({statId: "", statName: ""});
	}

	onSubmitTimeframe(startDate: string, endDate: string) {
		// Update the settings.
		this.setState({startDate, endDate});
	}

	render() {
		// setup
		const {client, user, system} = this.props;
		const {statId, statName} = this.state;

		// render
		if (!this.state.loaded) {
			return <Loading/>
		}
		return (
			<div className='section'>
				{this.renderSectionHeader(client, user)}
				{this.renderEngagementTables(client, user)}

				{ /* support */ }
				{!!statId && <AnalyticsForm
					system={system}
					statId={statId}
					statName={statName}
					client={client}
					cancel={this.onCancelAnalytics}
					submit={this.onSubmitAnalytics}
				/>}

				<hr/>
			</div>
		)
	}

	renderSectionHeader(client: Client, user: User) {
		// setup
		const {startDate, endDate} = this.state;
		const engagements = (user.isAdmin() && (user.email === 'joel@vesteap.com' || user.email === 'richard@lecticon.com' || user.email === 'kyle@lecticon.com'));

		// render
		return <div className='section-header'>
			<Tooltip id="lct-engagement-tooltip"/>
			<AiOutlineBarChart className="section-icon" size={28}/>
			Engagement Summary

			{ /* actions */ }
			<TimeframeDropdown
				startDate={startDate}
				endDate={endDate}
				onSubmitTimeframe={this.onSubmitTimeframe}
			/>
			<Button
				className='navigate-report nav-icon-wrapper'
				variant='secondary'
				size="sm"
				onClick={this.onGenerateReport}
			>
				<FiUsers
					className='nav-icon'
				/>
				Generate Report
			</Button>
			{engagements && <Button
				className='navigate-report nav-icon-wrapper'
				variant='secondary'
				size="sm"
				onClick={this.onNavigateEngagements}
			>
				<AiOutlineBarChart
					className="nav-icon"
					size={18}
				/>
				View Details
			</Button>}
		</div>
	}

	renderEngagementTables(client: Client, user: User) {
		// setup
		const {chartId, chartName, annualStats, monthlyStats, ctlMonths} = this.state;

		// render
		return <div className="row">
			<div className="column">
				<SummaryStats
					client={client}
					user={user}
					summary={annualStats}
					chartId={chartId}
					chartName={chartName}
					onEditAnalytics={this.onEditAnalytics}
					onSelectChart={this.onSelectChart}
				/>
			</div>
			<div className="column">
				{(chartId === 'utilization') ?
					<CareUtilization
						chartName={chartName}
						summary={monthlyStats}
						ctlMonths={ctlMonths}
					/> :
					<AnnualEngagements
						chartId={chartId}
						chartName={chartName}
						summary={monthlyStats}
						ctlMonths={ctlMonths}
					/>
				}
			</div>
		</div>
	}
}

