// react
import React from "react";
import {PageNav, PageProps, PollResponse, User, withNavigation} from "../types";
import {AppMenu, AppSidebar, AppSidebarIcon, Header} from "../components";
import {inject, observer} from "mobx-react";
import {Table} from "react-bootstrap";

/**
 * ForgotPassword
 */

interface SurveyProps extends PageProps {
}
interface SurveyState {
}

@inject("user")
@inject("client")
@observer
class SurveyComponent extends React.Component<SurveyProps, SurveyState> {

	constructor(props: SurveyProps) {
		// setup
		super(props);

		// Register the callbacks.
		this.onToggleSection = this.onToggleSection.bind(this);
	}

	addCheckinQuestions(item: PollResponse, data: any, index: number) {
		let tr = [];
		for (let i = 2; i < data.length; i++) {
			const colspan = data.length - 2;
			tr.push(<tr>
				<th colSpan={colspan} className="description">{`${data[i].id}. ${data[i].question}`}</th>
			</tr>);
		}
		return tr;
	}

	addCheckinNumbering(data: any) {
		let td = [];
		for (let i = 2; i < data.length; i++) {
			td.push(<td className="label">{data[i].id}</td>);
		}
		return <tr>
			{td}
		</tr>;
	}

	addCheckinItem(item: PollResponse, data: any, index: number) {
		// DATA => questions/responses
		const key = 'result-'+index;
		let td = [];
		for (let i = 2; i < data.length; i++) {
			if (i === 11) {
				td.push(<td className="singleline description">{data[i].response}</td>);
			} else {
				td.push(<td>{data[i].response}</td>);
			}
		}
		return (<tr key={key}>
			{td}
		</tr>);
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onToggleSection(section: string) {
		// setup
		const {navigate} = this.props;

		if (section === 'return') {
			navigate('/reports');
		}
	}

	render() {
		// setup
		const {client, user} = this.props;

		return (
			<div className="page">
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>

				<div id="StudentPage-body" className="body">
					{this.renderSidebar()}

					<div className="section-group">
						<div className="scroll-y-full">
							{this.renderSurvey()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderSidebar() {
		return (
			<AppSidebar>
				<AppSidebarIcon
					section='return'
					text='Return'
					state={'selected'}
					onSelect={this.onToggleSection}
				/>
			</AppSidebar>
		);
	}

	renderSurvey() {
		// setup
		const {client} = this.props;
		const responses = client.pollBreakdown;

		let company = null;
		let subcategory = null;
		let header = [];
		let body = [];
		for (let i = 0; i < responses.length; i++) {
			// setup
			const item = responses[i];
			const data = JSON.parse(item.response);
			const colspan = data.length - 2;

			// render
			if (item.company !== company) {
				company = item.company;
				subcategory = null;
				body.push(<tr key={'survey-response-company-'+i}>
					<td className="location" colSpan={colspan}>{company}</td>
				</tr>);
				body.push(this.addCheckinNumbering(data));
			}

			if (!header.length) {
				header.push(this.addCheckinQuestions(item, data, i));
			}
			body.push(this.addCheckinItem(item, data, i));
		}

		return (
			<Table id="Responses-Table" striped bordered>
				<thead>{header}</thead>
				<tbody>{body}</tbody>
			</Table>
		);
	}

}

export const SurveyPage = withNavigation(SurveyComponent);
