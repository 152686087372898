// react
import React from "react";
import {Button, Table} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import {NavigateFunction} from "react-router";

// local
import {
	PageProps,
	withNavigation,
} from "../types";
import {
	Footer,
	Loading,
	Header,
} from "../components";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction
}

const Subheader = (props: SubheaderProps) => {

	function onReports() {
		// setup
		const {navigate} = props;

		// Go to the engagements page.
		navigate("/reports");
	}

	return (
		<div className="subheader">
			<Button variant="forgot" size="sm" onClick={onReports}>
				Back to Reporting
			</Button>
		</div>
	);

}

/**
 * ReportsPage
 */

interface CoursesProps extends PageProps {
}
interface CoursesState {
	loaded: boolean,
	courses: any[],
	startDate: string,
	endDate: string
}

@inject("system")
@inject("client")
@inject("user")
@observer
export class CoursesPageComponent extends React.Component<CoursesProps, CoursesState> {

	constructor(props: CoursesProps) {
		super(props);

		this.state = {
			loaded: false,
			courses: [],
			startDate: '',
			endDate: ''
		}
	}

	componentDidMount = async () => {
		// setup
		const {client} = this.props;
		const {startDate, endDate} = this.state;

		const courses = await client.fetchCoursesAnalytics(client.name, startDate, endDate, false);
		this.setState({loaded: true, courses});
	}

	render() {
		// setup
		const {client, navigate} = this.props;
		const {loaded, courses} = this.state;
		if (!loaded) {
			return <Loading/>
		}

		const header = [
			<tr>
				<th className='form-md-3'>User</th>
				<th className="description">Title</th>
				<th className="date">enrolled</th>
				<th className="date">started</th>
				<th className="date">completed</th>
				<th className="date">time</th>
				<th className="percent">total</th>
			</tr>
		];

		let category = "";
		let body = [];
		for (let i = 0; i < courses.length; i++) {
			// setup
			const item = courses[i];
			const options:Intl.DateTimeFormatOptions = { year: "2-digit", month: "numeric", day: "2-digit"};

			const date = new Date(item.enrolled * 1000);
			const enrolled = date.toLocaleString('en-US', options);
			let started = '-';
			let startedDate = null;
			if (item.started) {
				startedDate = new Date(item.started * 1000);
				started = startedDate.toLocaleString('en-US', options);
			}
			let completed = '-';
			let completedDate = null;
			if (item.completed) {
				completedDate = new Date(item.completed * 1000);
				completed = completedDate.toLocaleString('en-US', options);
			}

			let diff = '-';
			if (completedDate && startedDate) {
				const timestamp = (completedDate.getTime() - startedDate.getTime()) / 1000;
				const days = Math.round(timestamp / (3600 * 24));
				const hours = Math.round(timestamp / 360) % 60;
				if (days) {
					diff = days + 'd';
				}
				if (hours) {
					diff = days ? diff + ',' + hours + 'h' : hours + 'h';
				} else if (hours === 1) {
					diff = days ? diff + ',1h' : '1h';
				}
			}

			// render
			if (item.company_name && item.company_name !== category) {
				category = item.company_name;
				body.push(<tr id={'course-views-category' + i}>
					<td className="location" colSpan={7} >{category}</td>
				</tr>);
			}
			body.push(<tr id={item.activity_id}>
				<td className='form-md-3'>{item.user_name}</td>
				<td className="description">{item.activity_title}</td>
				<td className="date">{enrolled}</td>
				<td className="date">{started}</td>
				<td className="date">{completed}</td>
				<td className="date">{diff}</td>
				<td className="value">{item.total}</td>
			</tr>);
		}

		// render
		return (
			<div className="page">
				<Header client={client} navigate={navigate}/>

				<Subheader
					navigate={navigate}
				/>

				<div className="body">
					<div id="CoursesEngagements">
						<Table id="Courses-Table" striped bordered responsive>
							<thead>{header}</thead>
							<tbody>{body}</tbody>
						</Table>
					</div>
				</div>

				<Footer/>
			</div>
		);
	}

}

export const CoursesPage = withNavigation(CoursesPageComponent);
