// react
import React from "react";

// local
import {system} from "../types"

/**
 * Footer
 */

export const Footer = (props: any) => {

	return (
		<div id="Footer" className="footer">
			{system.appCopyright}
			{props.children}
		</div>
	);
}

