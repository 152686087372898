// react
import React from "react";
import {inject, observer} from "mobx-react"
import {Table} from "react-bootstrap";
import moment from 'moment';
import axios from "axios";
import {IoBugOutline, IoReload} from "react-icons/io5";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";

// local
import {
	AppMenu,
	Footer,
	Header,
} from "../components";
import {
	API_MONDAY_AUTH,
	API_MONDAY_ID, API_MONDAY_SECRET,
	API_MONDAY_URL, ApiFeatureRecord, ApiIssueRecord,
	PageProps,
	withNavigation
} from "../types";
import {GoTasklist} from "react-icons/go";

const monday = axios.create({
	baseURL: API_MONDAY_URL,
	url: '',
	method: 'GET',
	withCredentials: false,
	headers: {
		Authorization: API_MONDAY_AUTH,
		client_id: API_MONDAY_ID,
		client_secret: API_MONDAY_SECRET
	}
});

/**
 * SupportPage
 */

interface SupportProps extends PageProps {
	navigate: NavigateFunction
}
interface SupportState {
	loaded: boolean,
	featuresTitle: string,
	features: ApiFeatureRecord[],
	issuesTitle: string,
	issues: ApiIssueRecord[]
}

@inject("user")
@inject("client")
@observer
class SupportPageComponent extends React.Component<SupportProps, SupportState> {

	constructor(props: SupportProps) {
		super(props);

		this.state = {
			loaded: false,
			featuresTitle: '',
			features: [],
			issuesTitle: '',
			issues: []
		};

		// Register the callbacks.
		this.onLogout = this.onLogout.bind(this);
		this.onReloadList = this.onReloadList.bind(this);
		this.onReports = this.onReports.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client} = this.props;

		// Get the current issues.
		await this.loadIssues(client.issuesBoard);
		await this.loadFeatures(client.featuresBoard);

		// done
		this.setState({loaded: false});
	}

	loadFeatures = async (id: string) => {
		// Get the current features.
		let options = new FormData();
		options.append('query', `query { boards(ids:${id}){id name items{id name group{id title} column_values{id title value text}}}}`);
		let results = await monday.post(API_MONDAY_URL, options);
		if (results.data && results.data.data && results.data.data.boards) {
			const board = results.data.data.boards[0];
			const features = this.apiFeatureToUI(board.items);
			this.setState({featuresTitle: board.name, features});
		}
	}

	loadIssues = async (id: string) => {
		// Get the current issues.
		let options = new FormData();
		options.append('query', `query { boards(ids:${id}){id name items{id name group{id title} column_values{id title value text}}}}`);
		let results = await monday.post(API_MONDAY_URL, options);
		if (results.data && results.data.data && results.data.data.boards) {
			const board = results.data.data.boards[0];
			const issues = this.apiIssueToUI(board.items);
			this.setState({issuesTitle: board.name, issues});
		}
	}

	apiFeatureToUI(data: any[]) {
		// Parse the API fields.
		let items: ApiFeatureRecord[] = [];
		for (let i = 0; i < data.length; i++) {
			// setup
			let item:ApiFeatureRecord = {
				id: data[i].id,
				created: '',
				description: '',
				environment: '',
				priority: '',
				status: data[i].group.title,
				reporter: ''
			}

			// Parse the values.
			let values: any[] = data[i].column_values;
			values.forEach(value => {
				if (value.title === 'Description') {
					item.description = value.text;
				} else if (value.title === 'Environment') {
					item.environment = value.text;
				} else if (value.title === 'Environment' || value.title === 'Club/School') {
					item.environment = value.text;
				} else if (value.title === 'Priority') {
					item.priority = value.text;
				} else if (value.title === 'Reporter') {
					item.reporter = value.text;
				} else if (value.title === 'Created') {
					item.created = moment(value.text).format('MM/DD/YY');
				}
			});
			items.push(item);
		}
		return items;
	}

	apiIssueToUI(data: any[]) {
		// Parse the API fields.
		let items: ApiIssueRecord[] = [];
		for (let i = 0; i < data.length; i++) {
			// setup
			let item:ApiIssueRecord = {
				id: data[i].id,
				created: '',
				description: '',
				environment: '',
				priority: '',
				status: data[i].group.title,
				reporter: ''
			}

			// Parse the values.
			let values: any[] = data[i].column_values;
			values.forEach(value => {
				if (value.title === 'Description') {
					item.description = value.text;
				} else if (value.title === 'Environment') {
					item.environment = value.text;
				} else if (value.title === 'Environment' || value.title === 'Club/School') {
					item.environment = value.text;
				} else if (value.title === 'Priority') {
					item.priority = value.text;
				} else if (value.title === 'Reporter') {
					item.reporter = value.text;
				} else if (value.title === 'Created') {
					item.created = moment(value.text).format('MM/DD/YY');
				}
			});
			items.push(item);
		}
		return items;
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onReloadList() {
	}

	onReports = () => {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/reports");
	}

	render() {
		// setup
		const {client, user} = this.props;
		const {issuesTitle, issues, featuresTitle, features} = this.state;

		// render
		return (
			<div className="page">
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>
				<div className="subheader">
					<Button className='navigate-report' variant='secondary' size="sm" onClick={this.onReports}>
						Back to Reporting
					</Button>
				</div>

				<div className="scroll-y-full">
					{this.renderIssues(issuesTitle, issues)}
					{this.renderFeatures(featuresTitle, features)}
				</div>

				<Footer/>
			</div>
		);
	}

	renderFooter() {
		// footer
		return <tfoot key='ticket-footer'><tr>
			<td colSpan={7}/>
		</tr></tfoot>;
	}

	renderHeader() {
		// header
		return <thead key='ticket-header'>
		<th className="col-1">#</th>
		<th className="col-1">Status</th>
		<th className="col-5">Description</th>
		<th className="col-2">App/Location</th>
		<th className="col-1">Priority</th>
		<th className="col-1">Created</th>
		<th className="col-2">Reporter</th>
		</thead>;
	}

	renderBody(issues: ApiIssueRecord[] | ApiFeatureRecord[]) {
		// body
		let body = [];
		for (let i = 0; i < issues.length; i++) {
			// render
			let issue = issues[i];
			body.push(
				<tr>
					<td className="col-1">{issue.id}</td>
					<td className="col-1">{issue.status}</td>
					<td className="col-5 justify-left">{issue.description}</td>
					<td className="col-2">{issue.environment}</td>
					<td className="col-1">{issue.priority}</td>
					<td className="col-1">{issue.created}</td>
					<td className="col-2">{issue.reporter}</td>
				</tr>
			)
		}
		return body;
	}

	renderFeatures(featuresTitle: string, features: ApiFeatureRecord[]) {
		// setup
		if (!features.length) {
			return null;
		}
		return (
			<div className='section'>
				<div className='section-header'>
					<GoTasklist className="section-icon" size={28}/>
					{featuresTitle}
					<IoReload
						className="section-reload"
						size={18}
						onClick={this.onReloadList}
					/>
				</div>

				<Table id="SupportTickets-Table" striped bordered responsive>
					{this.renderHeader()}
					{this.renderBody(features)}
					{this.renderFooter()}
				</Table>
			</div>
		);
	}

	renderIssues(issuesTitle: string, issues: ApiIssueRecord[]) {
		// setup
		if (!issues.length) {
			return null;
		}
		return (
			<div className='section'>
				<div className='section-header'>
					<IoBugOutline className="section-icon" size={28}/>
					{issuesTitle}
					<IoReload
						className="section-reload"
						size={18}
						onClick={this.onReloadList}
					/>
				</div>

				<Table id="SupportTickets-Table" striped bordered responsive>
					{this.renderHeader()}
					{this.renderBody(issues)}
					{this.renderFooter()}
				</Table>
			</div>
		);
	}
}

export const SupportPage = withNavigation(SupportPageComponent);
