// react
import React from "react";

// local
import {LOC_SDATE_FORMAT, UTC_DATE_FORMAT} from "../types"
import {Dropdown, Form} from "react-bootstrap";
import moment from "moment";

/**
 * Footer
 */

interface TimeframeProps {
	startDate: string,
	endDate: string,
	onSubmitTimeframe: (startDate: string, endDate: string) => void
}
interface TimeframeState {
}

export class TimeframeDropdown extends React.Component<TimeframeProps, TimeframeState> {

	constructor(props: TimeframeProps) {
		super(props);

		// Register the callbacks.
		this.onChangeValue = this.onChangeValue.bind(this);
		this.onSubmitTimeframe = this.onSubmitTimeframe.bind(this);
	}

	onChangeValue(id: string, value: string) {
		if (id === 'start_date') {
			this.setState({startDate: value});
		} else {
			this.setState({endDate: value});
		}
	}

	onSubmitTimeframe(eventKey: any) {
		// Update the settings.
		if (eventKey !== 'timeframe-custom') {
			const dates = eventKey.split(' ');
			this.props.onSubmitTimeframe(dates[0], dates[1]);
		} else {
			const startField = document.getElementById('start_date');
			// @ts-ignore
			const startDate = startField.value;
			const endField = document.getElementById('end_date');
			// @ts-ignore
			const endDate = endField.value;
			this.props.onSubmitTimeframe(startDate, endDate);
		}
	}

	render() {
		// setup
		const {startDate, endDate} = this.props;
		const now = moment();
		let year = now.year();
		let month = now.month();

		const strNow = now.format(LOC_SDATE_FORMAT);

		// month
		const startThisMonth = moment().startOf('month');
		const strStartThisMonth = startThisMonth.format(LOC_SDATE_FORMAT);
		const thisMonthKey = startThisMonth.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		const startLastMonth = moment().subtract(1, 'month').startOf('month');
		const endLastMonth = moment(startLastMonth).endOf('month');
		const strStartLastMonth = startLastMonth.format(LOC_SDATE_FORMAT);
		const strEndLastMonth = endLastMonth.format(LOC_SDATE_FORMAT);
		const lastMonthKey = startLastMonth.format(UTC_DATE_FORMAT)+' '+endLastMonth.format(UTC_DATE_FORMAT);

		// quarter
		let quarterYear = year;
		let quarterMonth = month;
		const startThisQuarter = moment().set('year', quarterYear).set('month', quarterMonth).startOf('quarter');
		const strStartThisQuarter = startThisQuarter.format(LOC_SDATE_FORMAT);
		const thisQuarterKey = startThisQuarter.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		if (quarterMonth >= 3) {
			quarterMonth -= 3;
		} else {
			quarterMonth = 9;
			quarterYear--;
		}
		const startLastQuarter = moment().set('year', quarterYear).set('month', quarterMonth).startOf('quarter');
		const endLastQuarter = moment().set('year', quarterYear).set('month', quarterMonth).endOf('quarter');
		const strStartLastQuarter = startLastQuarter.format('MM/DD/YY');
		const strEndLastQuarter = endLastQuarter.format('MM/DD/YY');
		const lastQuarterKey = startLastQuarter.format(UTC_DATE_FORMAT)+' '+endLastQuarter.format(UTC_DATE_FORMAT);

		// year
		const startThisYear = moment().set('year', year).set('month', 0).startOf('year');
		const strStartThisYear = startThisYear.format(LOC_SDATE_FORMAT);
		const thisYearKey = startThisYear.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		const startLastYear = moment().set('year', year-1).set('month', 0).startOf('year');
		const endLastYear = moment().set('year', year-1).set('month', 0).endOf('year');
		const strStartLastYear = startLastYear.format('MM/DD/YY');
		const strEndLastYear = endLastYear.format('MM/DD/YY');
		const lastYearKey = startLastYear.format(UTC_DATE_FORMAT)+' '+endLastYear.format(UTC_DATE_FORMAT);

		const start = moment(startDate).format('MM/DD/YY');
		const end = moment(endDate).format('MM/DD/YY');
		const title = start+'-' + end;

		// render
		return (
			<Dropdown className="timeframes" onSelect={this.onSubmitTimeframe}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{title}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{/* current */}
					<Dropdown.Item key='timeframe-this-month' eventKey={thisMonthKey}>
						<div>This Month <span>({strStartThisMonth}&ndash;{strNow})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-this-quarter' eventKey={thisQuarterKey}>
						<div>This Quarter <span>({strStartThisQuarter}&ndash;{strNow})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-this-year' eventKey={thisYearKey}>
						<div>This Year <span>({strStartThisYear}&ndash;{strNow})</span></div>
					</Dropdown.Item>

					{/* last */}
					<Dropdown.Item key='timeframe-last-month' eventKey={lastMonthKey} className="dropdown-section">
						<div>Last Month <span >({strStartLastMonth}&ndash;{strEndLastMonth})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-last-quarter' eventKey={lastQuarterKey}>
						<div>Last Quarter <span>({strStartLastQuarter}&ndash;{strEndLastQuarter})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-last-year' eventKey={lastYearKey}>
						<div>Last Year <span>({strStartLastYear}&ndash;{strEndLastYear})</span></div>
					</Dropdown.Item>

					{/* custom */}
					<Dropdown.Item key='timeframe-custom' eventKey='timeframe-custom' className="dropdown-section">
						<div className='help-title'>Custom &hellip;<span>SELECT</span></div>
						<div className='help-text'>choose dates from calendars below<br/>and press 'SELECT'</div>
					</Dropdown.Item>
					<Form.Group controlId="start_date" className="field-flex">
						<Form.Label className="field-flex-label">Start:</Form.Label>
						<Form.Control
							className="field-flex-value" type="date"
							onChange={(e) => this.onChangeValue(e.target.id, e.target.value)}
							defaultValue={startDate}
						/>
					</Form.Group>
					<Form.Group controlId="end_date" className="field-flex">
						<Form.Label className="field-flex-label">End:</Form.Label>
						<Form.Control
							className="field-flex-value" type="date"
							onChange={(e) => this.onChangeValue(e.target.id, e.target.value)}
							defaultValue={endDate}
						/>
					</Form.Group>
				</Dropdown.Menu>
			</Dropdown>
		);
	}

}

