// react
import React from "react";
import {AiOutlineBarChart} from "react-icons/ai";

// local
import {Client, LocationSummary} from "../../types";
import {LocationChart} from "./LocationChart";
import {LocationStats} from "./LocationStats";
import {LocationCourses} from "./LocationCourses";
import {Loading} from "../../components";

/**
 * LocationSection
 */

interface SectionProps {
	client: Client
}
interface SectionState {
	loaded: boolean,
	clientName: string,
	summary: LocationSummary[]
}

export class LocationSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		const {client} = props;

		this.state = {
			loaded: false,
			clientName: client.name,
			summary: []
		}
	}

	componentDidMount = async () => {
		// setup
		this.fetchLocationStats(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName} = this.state;

		// See if we need changes.
		if (pState.clientName !== client.name) {
			await this.setState({clientName: client.name, loaded: false});
		}
		if (pState.clientName !== clientName) {
			await this.fetchLocationStats(true);
		}
	}

	fetchLocationStats = async (force: boolean) => {
		// setup
		const {client} = this.props;

		// Update the summary information.
		const summary = await client.getLocationStats();
		this.setState({loaded: true, summary});
	}

	render() {
		// setup
		const {loaded, summary} = this.state;

		// render
		if (!loaded) {
			return <Loading className='vt-center'/>
		}
		return (
			<div className='section'>
				<div className='section-header'>
					<AiOutlineBarChart className="section-icon" size={28}/>
					Engagement Summary
				</div>

				<div className="row">
					<div className="column">
						<LocationStats summary={summary}/>
					</div>
					<div className="column">
						<LocationCourses summary={summary}/>
					</div>
				</div>
				<hr/>

			</div>
		)
	}
}

