// react
import React from "react";
import {ProgressBar, Table} from "react-bootstrap";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {Tooltip} from "react-tooltip";

// local
import {
	ActivitySummary,
	Client,
	SortActivityFilter, SortActivityFilterInfo,
	timestampToStrDate,
	TOOLTIP_DELAY
} from "../../types";
import {Loading, PopupMessage} from "../../components";


/**
 * Course Categories
 */

interface SummaryProps {
	client: Client,
	clientName: string,
	onSelectActivity: (activityId: string) => void,
	activityId: string
}
interface SummaryState {
	loaded: boolean,
	clientName: string,
	courses: ActivitySummary[],
	fCourses: ActivitySummary[],
	showInfoMessage: boolean,
	infoType: string,
	infoTitle: string,
	infoBody: any,
	search: string,
	sort: SortActivityFilter
}

export class CoursesSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);
		const {clientName} = props;

		// filters
		const sort = SortActivityFilterInfo.create({
			id: 'sort-desc',
			date: 'sort-desc',
			activity_category: 'sort-asc',
			activity_title: 'sort-asc',
			total: 'sort-desc',
			started: 'sort-desc',
			started_percent: 'sort-desc',
			completed: 'sort-desc',
			completed_percent: 'sort-desc',
			by: 'activity_category',
			direction: 'sort-asc'
		});

		this.state = {
			loaded: false,
			clientName: clientName,
			courses: [],
			fCourses: [],
			showInfoMessage: false,
			infoType: 'Engagement',
			infoTitle: "Navigating Summary of Courses",
			infoBody: <div>Select a course by clicking the list item. Once selected, the 'Viewing Details' list will show only that course, by location. <br/>Revert back to the global view by re-clicking the selected course.</div>,
			search: '',
			sort
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onShowPopup = this.onShowPopup.bind(this);
		this.onChangeSort = this.onChangeSort.bind(this);
	}

	componentDidMount = async () => {
		// setup
		this.fetchAnalytics(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName} = this.state;

		// See if we need changes.
		if (pState.clientName !== this.props.clientName) {
			await this.setState({clientName: this.props.clientName, loaded: false});
		}
		if (pState.clientName !== clientName) {
			await this.fetchAnalytics(true);
		}
	}

	fetchAnalytics = async (force: boolean) => {
		// setup
		const {client} = this.props;
		const {sort} = this.state;

		// Update the summary information.
		const infoType = client.getCourseType();
		const courses = client.getCourseSummary();
		const fCourses = this.filterBySort(courses, sort.by, sort.direction);
		this.setState({loaded: true, courses, fCourses, infoType});
	}

	filterBySort(courses: ActivitySummary[], sortBy: string, sortDirection: string): ActivitySummary[] {
		// Determine the sort selection.
		const f1 = sortBy;
		const f2 = (sortBy === 'activity_category') ? 'activity_title' : 'activity_category';

		// Filter according to the sort selection.
		return courses.sort((a: any, b: any) => {
			if (a[f1] === b[f1]) {
				return a[f2] > b[f2] ? 1 : -1;
			} else if (sortDirection === 'sort-asc') {
				return a[f1] > b[f1] ? 1 : -1;
			}
			return a[f1] > b[f1] ? -1 : 1;
		});
	}

	onChangeSort(sortBy: string, sortDir: string) {
		// setup
		let {sort, courses} = this.state;

		sort.set(sortBy, sortDir);
		const fCourses = this.filterBySort(courses, sort.by, sort.direction);
		this.setState({fCourses, sort});
	}

	onDismissPopup() {
		this.setState({showInfoMessage: false});
	}

	onShowPopup() {
		this.setState({showInfoMessage: true});
	}

	render() {
		// setup
		const {loaded, showInfoMessage, infoTitle, infoBody, fCourses, sort, infoType} = this.state;
		if (!loaded) {
			return <Loading/>
		}

		// Construct the list.
		let total = {
			enrolled: 0,
			started: 0,
			completed: 0
		};
		const showCategories = (sort.by === 'activity_category')
		let header, body, footer;
		if (infoType === 'Engagement') {
			header = this.renderEngagementHeader(sort);
			body = this.renderEngagementBody(fCourses, sort, showCategories, total)
			footer = this.renderEngagementFooter(total);
		} else {
			header = this.renderActivityHeader(sort);
			body = this.renderActivityBody(fCourses, sort, showCategories, total)
			footer = this.renderActivityFooter(total);
		}

		// render
		return (
			<div id="CoursesSummary">
				<AiOutlineInfoCircle
					className="info-icon"
					size={24}
					onClick={this.onShowPopup}
				/>
				<h4 className="title">Summary of Courses</h4>

				<Table id="CoursesSummary-Table" striped bordered responsive>
					<Tooltip id="lct-courses-tooltip"/>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>

				<PopupMessage
					show={showInfoMessage}
					title={infoTitle}
					body={infoBody}
					bg="info"
					onClose={this.onDismissPopup}
				/>
			</div>
		)
	}

	renderActivityBody(fCourses: ActivitySummary[], sort: SortActivityFilter, showCategories: boolean, total: any) {
		// setup
		const {activityId, onSelectActivity} = this.props;

		let category = '';
		let body = [];
		for (let i = 0; i < fCourses.length; i++) {
			// setup
			const item = fCourses[i];
			const key = 'summary'+total.enrolled;

			// render
			if (showCategories && item.activity_category !== category) {
				category = item.activity_category;
				body.push(<tr key={'course-summary-category-'+i}>
					<td className="location" colSpan={8} >{category}</td>
				</tr>);
			}

			// Determine the field values.
			total.enrolled += item.total;
			const title = item.activity_title ? item.activity_title : "";
			const selected = (title === activityId) ? 'selected' : 'selectable';

			const timestamp = timestampToStrDate(item.activity_id, false);

			const started = item.started ? item.started : 0;
			total.started += started;
			const sPercent = Math.ceil(item.started_percent) + '%';

			const completed = item.completed ? item.completed : 0;
			total.completed += completed;
			const cPercent = Math.ceil(item.completed_percent) + '%';

			// render
			body.push(<tr
				key={key}
				className={selected}
				onClick={() => onSelectActivity(title)}
				data-tooltip-id="lct-courses-tooltip"
				data-tooltip-content={item.activity_title}
				data-tooltip-place='top-start'
				data-tooltip-delay-show={TOOLTIP_DELAY}
			>
				<td className='tab'/>
				<td className="description singleline">{item.activity_title}</td>
				<td className="date">{timestamp}</td>
				<td className="value">{item.total.toLocaleString()}</td>
				<td className="value">{started.toLocaleString()}</td>
				<td className="percent started">
					<ProgressBar now={item.started_percent} label={sPercent} />
				</td>
				<td className="value">{completed.toLocaleString()}</td>
				<td className="percent completed">
					<ProgressBar now={item.completed_percent} label={cPercent} />
				</td>
			</tr>);
		}
		if (!fCourses.length) {
			body.push(<tr key={'courses-empty'}>
				<td className="description warning" colSpan={6}>No Activity</td>
			</tr>);
		}

		// Return the body.
		return body;
	}

	renderActivityFooter(total: any) {
		// setup
		const sPercent = total.enrolled ? Math.ceil(total.started * 100 / total.enrolled) + '%' : 'n/a';
		const cPercent = total.enrolled ? Math.ceil(total.completed * 100 / total.enrolled) + '%' : 'n/a';

		// Return the footer.
		return <tr key='courses-footer'>
			<td className='tab'/>
			<td className="description"></td>
			<td className="date"></td>
			<td className="value">{total.enrolled.toLocaleString()}</td>
			<td className="value">{total.started.toLocaleString()}</td>
			<td className="percent">{sPercent}</td>
			<td className="value">{total.completed.toLocaleString()}</td>
			<td className="percent">{cPercent}</td>
		</tr>;
	}

	renderActivityHeader(sort: SortActivityFilter) {
		// Return the header.
		return [
			<tr key='courses-header'>
				<th
					className={'tab ' + sort.activity_category + (sort.by === 'activity_category' ? ' active' : '')}
					onClick={() => this.onChangeSort('activity_category', sort.activity_category)}
				/>
				<th
					className={'description ' + sort.activity_title + (sort.by === 'activity_title' ? ' active' : '')}
					onClick={() => this.onChangeSort('activity_title', sort.activity_title)}
				>
					Course
				</th>
				<th
					className={'date ' + sort.date + (sort.by === 'date' ? ' active' : '')}
					onClick={() => this.onChangeSort('date', sort.date)}
				>
					released
				</th>
				<th
					className={'value ' + sort.total + (sort.by === 'total' ? ' active' : '')}
					onClick={() => this.onChangeSort('total', sort.total)}
				>
					enrolled
				</th>
				<th
					className={'value ' + sort.started + (sort.by === 'started' ? ' active' : '')}
					onClick={() => this.onChangeSort('started', sort.started)}
				>
					started
				</th>
				<th
					className={'percent ' + sort.started_percent + (sort.by === 'started_percent' ? ' active' : '')}
					onClick={() => this.onChangeSort('started_percent', sort.started_percent)}
				>
					%
				</th>
				<th
					className={'value ' + sort.completed + (sort.by === 'completed' ? ' active' : '')}
					onClick={() => this.onChangeSort('completed', sort.completed)}
				>
					finished
				</th>
				<th
					className={'percent ' + sort.completed_percent + (sort.by === 'completed_percent' ? ' active' : '')}
					onClick={() => this.onChangeSort('completed_percent', sort.completed_percent)}
				>
					%
				</th>
			</tr>
		];
	}

	renderEngagementBody(fCourses: ActivitySummary[], sort: SortActivityFilter, showCategories: boolean, total: any) {
		// setup
		const {activityId, onSelectActivity} = this.props;

		let category = '';
		let body = [];
		for (let i = 0; i < fCourses.length; i++) {
			// setup
			const item = fCourses[i];
			const key = 'summary'+total.enrolled;

			// render
			if (showCategories && item.activity_category !== category) {
				category = item.activity_category;
				body.push(<tr key={'course-summary-category-'+i}>
					<td className="location" colSpan={4} >{category}</td>
				</tr>);
			}

			// Determine the field values.
			total.enrolled += item.total;
			const title = item.activity_title ? item.activity_title : "";
			const selected = (title === activityId) ? 'selected' : 'selectable';

			total.started += item.started;
			total.completed += item.completed;

			// render
			body.push(<tr
				key={key}
				className={selected}
				onClick={() => onSelectActivity(title)}
				data-tooltip-id="lct-courses-tooltip"
				data-tooltip-content={item.activity_title}
				data-tooltip-place='top-start'
				data-tooltip-delay-show={TOOLTIP_DELAY}
			>
				<td className='tab'/>
				<td className="description singleline">{item.activity_title}</td>
				<td className="value">{item.started.toLocaleString()}</td>
				<td className="value">{item.total.toLocaleString()}</td>
			</tr>);
		}
		if (!fCourses.length) {
			body.push(<tr key={'courses-empty'}>
				<td className="description warning" colSpan={6}>No Activity</td>
			</tr>);
		}

		// Return the body.
		total.columns = 4;
		return body;
	}

	renderEngagementFooter(total: any) {
		// Return the footer.
		return <tr key='courses-footer'>
			<td className='tab'/>
			<td className="description"></td>
			<td className="value">{total.started.toLocaleString()}</td>
			<td className="value">{total.enrolled.toLocaleString()}</td>
		</tr>;
	}

	renderEngagementHeader(sort: SortActivityFilter) {
		// Return the header.
		return [
			<tr key='courses-header'>
				<th
					className={'tab ' + sort.activity_category + (sort.by === 'activity_category' ? ' active' : '')}
					onClick={() => this.onChangeSort('activity_category', sort.activity_category)}
				/>
				<th
					className={'description ' + sort.activity_title + (sort.by === 'activity_title' ? ' active' : '')}
					onClick={() => this.onChangeSort('activity_title', sort.activity_title)}
				>
					Course
				</th>
				<th
					className={'value ' + sort.started + (sort.by === 'started' ? ' active' : '')}
					onClick={() => this.onChangeSort('started', sort.started)}
				>
					Users
				</th>
				<th
					className={'value ' + sort.total + (sort.by === 'total' ? ' active' : '')}
					onClick={() => this.onChangeSort('total', sort.total)}
				>
					Views
				</th>
			</tr>
		];
	}
}
