// react
import React from "react";

// local
import {Button} from "react-bootstrap";

/**
 * NavSearch
 */

interface NavProps {
	text: string
	onClearSearch: (event: any) => void
	onUpdateSearch: (event: any) => void
}

export const NavSearch = (props: NavProps) => {

	return (
		<div className='navigate-search'>
			<input
				type='text'
				placeholder={props.text}
				onSelect={props.onUpdateSearch}
			/>
			<span
				className="clearsearch"
				onClick={props.onClearSearch}
			/>
		</div>
	);
}

