// react
import React from "react";
import {Button, Form} from "react-bootstrap";
import moment from 'moment';

// local
import {ApiFieldOption, Client, UTC_DATE_FORMAT} from "../types";
import {PopupMessage} from "../components";

/**
 * IssueForm
 */

interface FormProps {
	client: Client,
	title: string,
	board: string,
	options: ApiFieldOption[],
	close: () => void,
}
interface FormState {
	showSaveMessage: boolean
}

export class SupportMondayForm extends React.Component<FormProps, FormState> {

	constructor(props: FormProps) {
		super(props);

		this.state = {
			showSaveMessage: false,
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onFormClose = this.onFormClose.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	componentDidMount = async () => {
	}

	dateForPicker (dateString: string) {
		return moment(new Date(dateString)).format(UTC_DATE_FORMAT);
	}

	onChangeValue(item: any, event: any) {
	}

	onDismissPopup() {
		this.setState({showSaveMessage: false});
	}

	onFormClose = async (event: any) => {
		// setup
		this.props.close();
	}

	onFormSubmit = async (event: any) => {
		// setup
		let query = "mutation { create_item (board_id: 1234567890, group_id: \"Group 1\", item_name: \"new item\", column_values: \"{\\\"date4\\\":\\\"2023-05-25\\\"}\") { id }}";


		this.setState({showSaveMessage: true});
	}

	render() {
		// setup
		const {title} = this.props;
		const {showSaveMessage} = this.state;

		// render
		return (
			<div id="ClientForm">
				<Form className="form-popup" onSubmit={this.onFormSubmit}>

					{this.renderFormHeader(title)}
					{this.renderFormBody()}
					{this.renderFormFooter()}

				</Form>

				<PopupMessage
					show={showSaveMessage}
					title={`Your issue has been submitted.`}
					body={showSaveMessage}
					onClose={this.onDismissPopup}
				/>

			</div>
		);
	}

	renderFormBody() {
		// setup
		const {options} = this.props;
		const fields = options.map(issue => {
			if (issue.type === 'text') {
				return this.renderText(issue);
			} else if (issue.type === 'textarea') {
				return this.renderTextArea(issue);
			} else if (issue.type === 'select-single') {
				return this.renderSelectSingle(issue);
			}
		});

		// render
		return (
			<div className="form-body">
				{fields}
			</div>
		)
	}

	renderText(option: ApiFieldOption) {
		return <Form.Group controlId={option.id} className="form-field">
			<Form.Label className="field-md-label">{option.title + ':'}</Form.Label>
			<Form.Control
				className="field-lg-value form-control" type="text"
				placeholder={option.values}
			/>
		</Form.Group>
	}

	renderTextArea(option: ApiFieldOption) {
		return <Form.Group controlId={option.id} className="form-field">
			<Form.Label className="field-md-label">{option.title + ':'}</Form.Label>
			<Form.Control
				className="field-lg-value form-control" as="textarea"
				placeholder='(include brief description of the problem)'
				rows={3}
			/>
		</Form.Group>
	}

	renderSelectSingle(option: ApiFieldOption) {
		// Determine the options.
		const data = JSON.parse(option.values) as any[];
		const options = data.map(item => {
			return (item.id === option.default) ?
				<option value={item.id} selected>{item.label}</option> :
				<option value={item.label}>{item.label}</option>;
		});

		return <Form.Group controlId={option.id} className="form-field">
			<Form.Label className="field-md-label">{option.title + ':'}</Form.Label>
			<Form.Select className="field-md-value form-control">
				{options}
			</Form.Select>
		</Form.Group>
	}

	renderFormFooter() {
		return (
			<div className="form-footer">
				<Button className="btn form-btn" variant="forgot" size="sm" onClick={this.onFormClose}>
					Close
				</Button>
				<Button type="submit" className="btn form-btn" size="sm" onClick={this.onFormSubmit}>
					Submit
				</Button>
			</div>
		)
	}

	renderFormHeader(title: string) {
		return (
			<div className="form-header">
				{title}
				<Button id='ClosePopup' variant="forgot" className="btn form-btn" size="sm" onClick={this.onFormClose}>
					X
				</Button>
			</div>
		)
	}
}

