// react
import React from "react";

/**
 * DropdownHr (with wrapper)
 */

export const DropdownHr = () => {

	return (
		<div className="dropdown-hr-wrapper">
			<hr/>
		</div>
	);
}
