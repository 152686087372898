// react
import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

// local
import {LEGEND_STYLE, CHART_TOOLTIP_STYLE} from "../../types";

/**
 * SummaryChart
 */

interface ChartProps {
	title: string,
	data: any[]
}
interface ChartState {
}

export class SummaryChart extends React.Component<ChartProps, ChartState> {

	tooltip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">{payload[0].name}</div>
			);
		}
		return null;
	};

	render() {
		// setup
		const {title, data} = this.props;
		const cells = data.map((item, i) =>
			<Cell key={`cell-${i}`} fill={item.color}/>);

		// render
		return (
			<div className={"column bordered"}>
				<h4>{title}</h4>
				<ResponsiveContainer height={300}>
					<PieChart>
						<Pie
							data={data}
							outerRadius={95}
							dataKey="value"
							labelLine={false}
						>
							{cells}
						</Pie>
						<Tooltip content={this.tooltip} wrapperStyle={CHART_TOOLTIP_STYLE}/>
						<Legend height={90} verticalAlign="bottom" iconSize={5} wrapperStyle={LEGEND_STYLE}/>
					</PieChart>
				</ResponsiveContainer>
			</div>
		);
	}

}
