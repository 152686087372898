// react
import React from "react";
import packageJson from '../../package.json';

// local
import {system} from "../types";

/**
 * Header
 */

export const Header = (props: any) => {
	// setup
	const {version} = packageJson;
	const client = props.client ? props.client : null;

	// render
	return (
		<div id="Header" className="header">
			<img className="left-logo" alt="Application Logo" src={system.appLogo}/>
			<h4 className="app-title">{system.appName}</h4>
			{client && client.logo &&
				<img
					className="center-logo"
					src={client.logo}
					alt="Client Logo"
				/>
			}
			<div className="app-version">{`v${version}`}</div>
			{props.children}
		</div>
	);
}
