// react
import React from "react";
import {ProgressBar, Table} from "react-bootstrap";
import {AiOutlineInfoCircle} from "react-icons/ai";

// local
import {PollSummary} from "../../types";
import {PopupMessage} from "../../components";

/**
 * Course Categories
 */

interface SummaryProps {
	year: number,
	month: number,
	summary: PollSummary[],
	onSelectCompany: (companyId: string) => void,
	companyId: string
}
interface SummaryState {
	showInfoMessage: boolean,
	infoTitle: string,
	infoBody: any,
}

export class SurveySummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			showInfoMessage: false,
			infoTitle: "Navigating Survey Completion",
			infoBody: <div>Select a location by clicking the list item. Once selected, the 'Response Details' list will show only that location's results. <br/>Revert back to the global view by re-clicking the selected location.</div>
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onShowPopup = this.onShowPopup.bind(this);
	}

	onDismissPopup() {
		this.setState({showInfoMessage: false});
	}

	onShowPopup() {
		this.setState({showInfoMessage: true});
	}

	render() {
		// setup
		const {summary, companyId, year, month} = this.props;
		const {showInfoMessage, infoTitle, infoBody} = this.state;

		// Determine the components.
		let total = {
			total: 0,
			completed: 0
		};
		const header = this.renderHeader(year, month);
		const body = this.renderBody(summary, companyId, total);
		const footer = this.renderFooter(total);

		// render
		return (
			<div id="SurveySummary" key="SurveySummary">
				<AiOutlineInfoCircle
					className="info-icon"
					size={24}
					onClick={this.onShowPopup}
				/>
				<h4 className="title">Survey Participation</h4>

				<Table id="SurveySummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>

				<PopupMessage
					show={showInfoMessage}
					title={infoTitle}
					body={infoBody}
					bg="info"
					onClose={this.onDismissPopup}
				/>
			</div>
		)
	}

	renderBody(summary: PollSummary[], companyId: string, total: any) {
		// setup
		const {onSelectCompany} = this.props;

		// render
		let body = [];
		for (let i = 0; i < summary.length; i++) {
			// setup
			const item = summary[i];
			const id = 'survey-summary-' + i;

			const selected = (item.company === companyId) ? 'selected' : 'selectable';
			total.completed += item.completed;
			total.total += item.total;

			const cPercent = item.completed_percent+'%';

			body.push(
				<tr
					key={id}
					className={selected}
					onClick={() => onSelectCompany(item.company)}
				>
					<td className="index">{i}</td>
					<td className="description">{item.title}</td>
					<td className="value">{item.total}</td>
					<td className="value">{item.started}</td>
					<td className="value completed">{}
						<ProgressBar now={item.completed_percent} label={cPercent}/>
					</td>
				</tr>
			);
		}
		return body;
	}

	renderFooter(total: any) {
		// setup
		const pTotal = total.total ? Math.ceil(total.completed * 100 / total.total) + '%' : '0%';

		// render
		return <tr key='survey-summary-footer'>
			<td colSpan={2}></td>
			<td className="value">{total.total.toLocaleString()}</td>
			<td className="value">{total.completed.toLocaleString()}</td>
			<td className="value">{pTotal}</td>
		</tr>
	}

	renderHeader(year: number, month: number) {
		// setup
		const timestamp = month + "/" + (year - 2000);

		// render
		return <tr key='survey-summary-header'>
			<th className="index">#</th>
			<th className="description">Location</th>
			<th className="value">users</th>
			<th className="value">{timestamp}</th>
			<th className="value">%</th>
		</tr>
	}

}
