// react
import React from "react";
import {Table} from "react-bootstrap";
import {NavigateFunction} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {FaRegBuilding} from "react-icons/fa";

// local
import {
	firstLastToName,
	Client,
	SalesInfo,
	SortSalesFilter,
	User,
	TOOLTIP_DELAY,
} from "../../types";

/**
 * SalesSummary
 */

interface SalesProps {
	user: User,
	client: Client
	navigate: NavigateFunction,
	sales: SalesInfo[],
	sort: SortSalesFilter,
	onChangeSort: (sortBy: string, sortDir: string) => void
}
interface SalesState {
	salesId: number
}

export class SalesSummary extends React.Component<SalesProps, SalesState> {

	constructor(props: SalesProps) {
		super(props);

		// initial state
		this.state = {
			salesId: 0
		}

		// Register the callbacks.
		this.onSelectItem = this.onSelectItem.bind(this);
		this.onViewItem = this.onViewItem.bind(this);
	}

	onSelectItem(salesId: number) {
		this.setState({salesId});
	}

	onViewItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to the student page.
		user.setStudentInfo(studentId, orderId, courseId);
		navigate("/student");
	}

	render() {
		// Construct the table.
		let summary = {
			qty: 0,
			subtotal: 0,
			discount: 0,
			coupons: 0,
			total: 0
		};

		const header = this.renderHeader();
		const body = this.renderBody(summary);
		const footer = this.renderFooter(summary);

		// render
		return (
			<div id="SalesSummary">
				<Table id="SalesSummary-Table" striped bordered responsive>
					<Tooltip id="lct-sales-tooltip"/>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		)
	}

	renderBody(summary: any) {
		// setup
		const {sales, sort} = this.props;
		const {salesId} = this.state;
		const showCategories = (sort.by === 'company_name');

		// Create the body elements.
		let category = "";
		const body = [];
		for (let i = 0; i < sales.length; i++) {
			// item
			const item = sales[i];

			// Check the category section.
			if (showCategories && item.company_name !== category) {
				category = item.company_name;
				body.push(<tr key={'sales-category-'+item.id}>
					<td className="location" colSpan={10}>{category}</td>
				</tr>);
			}

			// sales information
			summary.qty += item.qty;
			summary.subtotal += item.subtotal;
			summary.discount += item.discount;
			summary.total += item.total;
			if (item.coupon) {
				summary.coupons++;
			}

			const selected = (item.id === salesId) ? 'selectable selected' : 'selectable';
			let name = firstLastToName(item.user_first_name, item.user_last_name);
			const coupon = item.coupon ? item.coupon : '';

			// render
			body.push(
				<tr
					key={'sale-'+i}
					className={selected}
					onClick={() => this.onSelectItem(item.id)}
					data-tooltip-id="lct-sales-tooltip"
					data-tooltip-content={item.order_item_name}
					data-tooltip-place='top-start'
					data-tooltip-delay-show={TOOLTIP_DELAY}
				>
					<td className='tab'/>
					<td
						className='link'
						onClick={() => this.onViewItem(item.mdl_user_id, item.order_id, 0)}
					>
						{item.order_id}
					</td>
					<td className='student'>
						<div>{name}</div>
						{item.member_id && <div className='sub-description'>{'#'+item.member_id}</div>}
					</td>
					{showCategories ?
						<td className='description'>{item.order_item_name}</td> :
						<td className='description'>
							<div>{item.order_item_name}</div>
							<div className='sub-description'>{item.company_name}</div>
						</td>
					}
					<td className='date'>{item.sCreated}</td>
					<td className='value'>{item.qty}</td>
					<td className='value'>{item.subtotal.toFixed(2)}</td>
					<td className='coupon'>{coupon}</td>
					<td className='value'>{item.discount.toFixed(2)}</td>
					<td className='value'>{item.total.toFixed(2)}</td>
				</tr>
			);
		}

		// Return the body.
		return body;
	}

	renderFooter(summary: any) {
		// setup
		const fCurrency = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 2
		});

		// render
		return <tr key='sales-company-footer'>
			<td colSpan={5}/>
			<td className={'value'}>{summary.qty.toLocaleString()}</td>
			<td className={'value'}>{fCurrency.format(summary.subtotal)}</td>
			<td className={'value'}>{summary.coupons}</td>
			<td className={'value'}>{fCurrency.format(summary.discount)}</td>
			<td className={'value'}>{fCurrency.format(summary.total)}</td>
		</tr>;
	}

	renderHeader() {
		// setup
		const {sort, onChangeSort} = this.props;

		// render
		return [
			<tr key='sales-company-header'>
				<th
					className={'tab ' + sort.company_name + (sort.by === 'company_name' ? ' active' : '')}
					onClick={() => onChangeSort('company_name', sort.company_name)}
				>
					<FaRegBuilding className='field-icon' size={9} color='#000000'/>
				</th>
				<th
					className={'value ' + sort.id + (sort.by === 'id' ? ' active' : '')}
					onClick={() => onChangeSort('id', sort.id)}
				>
					ID
				</th>
				<th
					className={'student ' + sort.user_last_name + (sort.by === 'user_last_name' ? ' active' : '')}
					onClick={() => onChangeSort('user_last_name', sort.user_last_name)}
				>
					Student
				</th>
				<th
					className={'description ' + sort.order_item_name + (sort.by === 'order_item_name' ? ' active' : '')}
					onClick={() => onChangeSort('order_item_name', sort.order_item_name)}
				>
					Course
				</th>
				<th
					className={'date ' + sort.created + (sort.by === 'created' ? ' active' : '')}
					onClick={() => onChangeSort('created', sort.created)}
				>
					Date
				</th>
				<th className="value">Qty</th>
				<th className="value">Subtotal</th>
				<th className="coupon">Coupon</th>
				<th className="value">Discount</th>
				<th className="value">Total</th>
			</tr>
		];
	}

}
