// react
import React from "react";
import {AiOutlineBarChart, AiOutlineHome, AiOutlineLogin} from "react-icons/ai";
import {BiPieChart, BiLibrary, BiSupport} from "react-icons/bi";
import {BsListTask, BsPersonCheck, BsCart2} from "react-icons/bs";
import {FiUsers, FiSettings} from "react-icons/fi";
import {FaCertificate} from "react-icons/fa"
import {GiReturnArrow} from "react-icons/gi"
import {TbSchool} from "react-icons/tb";
import {RiChatPollLine, RiCouponLine} from "react-icons/ri";

// local
import {SIDEBAR_ICON_SIZE} from "../types";

/**
 * AppSidebar
 */

interface AppSidebarIconProps {
	section: string
	text: string
	state: string
	onSelect: (section: string) => void
}

export const AppSidebarIcon = (props: AppSidebarIconProps) => {
	// setup
	const {section, text, onSelect, state} = props;

	// render
	if (!state) {
		return null;
	}
	return (
		<div className={`sidebar-icon-wrapper ${state} selectable`} onClick={() => onSelect(section)}>
			{section === 'category' && <TbSchool className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'coupons' && <RiCouponLine className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'courses' && <TbSchool className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'engagement' && <AiOutlineBarChart className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'home' && <AiOutlineHome className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'insights' && <BiPieChart className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'location' && <AiOutlineBarChart className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_logins' && <AiOutlineLogin className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'sales' && <BsCart2 className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'settings' && <FiSettings className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'students' && <FiUsers className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'support' && <BiSupport className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 'survey' && <RiChatPollLine className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_certificates' && <FaCertificate className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_courses' && <BiLibrary className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_exams' && <BsListTask className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_validation' && <BsPersonCheck className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_orders' && <BsCart2 className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			{section === 's_return' && <GiReturnArrow className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>}
			<div className="sidebar-icon-text">{text}</div>
			{state !== 'static' && <div className={(state === 'selected') ? 'hide' : 'show'}/>}
		</div>
	);
}

export const AppSidebar = (props: any) => {
	// setup
	// render
	return (
		<div id="AppSidebar">
			{props.children}
		</div>
	);
}

