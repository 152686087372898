// react
import React from "react";
import {AiOutlineBarChart} from "react-icons/ai";
import {IoReload} from "react-icons/io5";
import {BsCart2} from "react-icons/bs";
import {NavigateFunction} from "react-router-dom";

// local
import {
	Client, CourseInfo,
	SalesInfo,
	SortSalesFilter,
	SortSalesFilterInfo, system,
	User
} from "../../types";
import {SalesSummary} from "./SalesSummary"
import {Loading, NavButton, NavSearch, TimeframeDropdown} from "../../components";

/**
 * SalesSection
 */

interface SectionProps {
	user: User,
	client: Client
	navigate: NavigateFunction
}
interface SectionState {
	loaded: boolean,
	clientName: string
	sales: SalesInfo[],
	fSales: SalesInfo[],
	search: string,
	sort: SortSalesFilter,
	startDate: string,
	endDate: string
}

export class SalesSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		const {client} = props;

		// filters
		const sort = SortSalesFilterInfo.create({
			id: 'sort-desc',
			company_name: 'sort-asc',
			user_last_name: 'sort-desc',
			order_item_name: 'sort-asc',
			created: 'sort-desc',
			by: 'created',
			direction: 'sort-desc'
		});

		// initial state
		this.state = {
			clientName: client.name,
			loaded: false,
			sales: [],
			search: '',
			fSales: [],
			sort: sort,
			startDate: client.salesStartDate,
			endDate: client.salesEndDate
		}

		// Register the callbacks.
		this.onChangeSort = this.onChangeSort.bind(this);
		this.onClearSearch = this.onClearSearch.bind(this);
		this.onGenerateReport = this.onGenerateReport.bind(this);
		this.onReloadList = this.onReloadList.bind(this);
		this.onSubmitTimeframe = this.onSubmitTimeframe.bind(this);
		this.onUpdateSearch = this.onUpdateSearch.bind(this);
	}

	componentDidMount = async () => {
		// setup
		this.fetchSales(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName, startDate, endDate} = this.state;

		// See if we need changes.
		if (pState.clientName !== client.name) {
			await this.setState({clientName: client.name});
		}
		if (pState.clientName !== clientName ||
			pState.startDate !== startDate || pState.endDate !== endDate) {
			this.fetchSales(true);
		}
	}

	fetchSales = async (force: boolean) => {
		// setup
		const {client} = this.props;
		const {startDate, endDate, sort, search} = this.state;

		// Update the sales information.
		const sales = await client.fetchSalesAnalytics(client.name, startDate, endDate, force);
		const fSales = this.filterBySortAndSearch(sales, sort.by, sort.direction, search);

		this.setState({loaded: true, sales, fSales});
	}

	filterBySearch(sales: SalesInfo[], search: string): SalesInfo[] {
		// See if there are any matches.
		if (search) {
			search = search.toLowerCase();
			return sales.filter(item => item.search.includes(search));
		}
		return sales;
	}

	filterBySort(sales: SalesInfo[], sortBy: string, sortDirection: string): SalesInfo[] {
		// Determine the sort selection.
		const f1 = sortBy;
		const f2 = (sortBy === 'id') ? 'order_item_name' : 'id';

		// Filter according to the sort selection.
		return sales.sort((a: any, b: any) => {
			if (a[f1] === b[f1]) {
				return a[f2] > b[f2] ? 1 : -1;
			} else if (sortDirection === 'sort-asc') {
				return a[f1] > b[f1] ? 1 : -1;
			}
			return a[f1] > b[f1] ? -1 : 1;
		});
	}

	filterBySortAndSearch(sales: SalesInfo[], sortBy: string, sortDirection: string, search: string) {
		return this.filterBySearch(this.filterBySort(sales, sortBy, sortDirection), search);
	}

	onChangeSort(sortBy: string, sortDir: string) {
		// setup
		let {sales, sort, search} = this.state;

		sort.set(sortBy, sortDir);
		const fSales = this.filterBySortAndSearch(sales, sort.by, sort.direction, search);
		this.setState({fSales, sort});
	}

	onClearSearch(event: any) {
		// setup
		const {sales, search} = this.state;
		const target = event.target.previousSibling;
		target.value = '';

		// Filter by the search value.
		if (search !== target.value) {
			const fSales = this.filterBySearch(sales, target.value);
			this.setState({fSales, search: target.value});
		}
	}

	onGenerateReport = async () => {
		// setup
		const {user, client} = this.props;
		const {startDate, endDate} = this.state;

		// Generate the sales report.
		const report = user.isCompanyManager() ? 'accounting' : 'sales';
		await client.generateReport(client.name, report, startDate, endDate);
	}

	onReloadList = async () => {
		// Refetch the list.
		this.fetchSales(true);
	}

	onSubmitTimeframe(startDate: string, endDate: string) {
		// Update the settings.
		this.setState({startDate, endDate});
	}

	onUpdateSearch(event: any) {
		// setup
		const {sales, search} = this.state;
		const target = event.target;

		// Filter by the search value.
		if (target.value !== search) {
			const fSales = this.filterBySearch(sales, target.value);
			this.setState({fSales, search: target.value});
		}
	}

	render() {
		// setup
		const header = this.renderSectionHeader();
		const body = this.renderSalesTable();

		// render
		return (
			<div>
				<div className='section'>
					{header}
					{body}
					<hr/>
				</div>
			</div>
		)
	}

	renderSectionHeader() {
		// setup
		const {startDate, endDate} = this.state;

		// render
		return <div className='section-header'>
			<AiOutlineBarChart className="section-icon" size={28}/>
			Sales Summary
			<IoReload
				className="section-reload"
				data-tooltip-id="lct-reporting-tooltip"
				data-tooltip-content="Click to refresh list"
				data-tooltip-place='right'
				size={18}
				onClick={this.onReloadList}
			/>

			{ /* actions */ }
			<TimeframeDropdown
				startDate={startDate}
				endDate={endDate}
				onSubmitTimeframe={this.onSubmitTimeframe}
			/>
			<NavButton
				className='navigate-report'
				text='Generate Report'
				icon={<BsCart2 className="nav-icon" size={18}/>}
				onClick={this.onGenerateReport}
			/>
			<NavSearch
				text='Search for...'
				onClearSearch={this.onClearSearch}
				onUpdateSearch={this.onUpdateSearch}
			/>
		</div>
	}

	renderSalesTable() {
		// setup
		const {user, client, navigate} = this.props;
		const {loaded, fSales, sort} = this.state;

		// render
		if (!loaded) {
			return <div className="row" style={{height: 20}}>
				<Loading className='vt-center'/>
			</div>;
		}
		return <div className="row">
			<SalesSummary
				user={user}
				client={client}
				navigate={navigate}
				sales={fSales}
				sort={sort}
				onChangeSort={this.onChangeSort}
			/>
		</div>
	}

}
