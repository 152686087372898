// react
import React from "react";
import {Button, Form} from "react-bootstrap";
import moment from 'moment';

// local
import {clients, CompanyDetails, NameValuePair, system, UTC_DATE_FORMAT} from "../types";
import {PopupMessage} from "../components";

/**
 * Info
 */

interface InfoProps {
	clientId: number,
	action: string,
	close: () => void,
}
interface InfoState {
	loaded: boolean,
	edit: boolean,
	company: CompanyDetails | null,
	showSaveMessage: string | null
}

export class ClientDetailsForm extends React.Component<InfoProps, InfoState> {

	constructor(props: InfoProps) {
		super(props);

		this.state = {
			loaded: false,
			edit: (props.action === 'edit'),
			company: null,
			showSaveMessage: null
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onFormClose = this.onFormClose.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {clientId} = this.props;
		const {edit} = this.state;

		// Get the client details.
		if (edit) {
			const company = await clients.fetchClientInfo(clientId);
			this.setState({loaded: true, company});
		} else {
			const parent = clients.findByName(system.rootId);
			const company = {
				// basic
				id: 0,
				name: "New Client/Location",
				shortname: "",
				parentid: parent ? parent.id : 0,
				code: "",
				logo: "",
				// address
				address: "",
				city: "",
				postcode: "",
				country: "",
				locale: "",
				// contact
				contact_name: "",
				contact_email: "",
				contact_phone: "",
				// broker
				broker_name: "",
				broker_email: "",
				broker_phone: "",
				// billing
				billing_name: "",
				billing_email: "",
				billing_phone: "",
				// other
				census: 0,
				office_census: 0,
				other_census: 0,
				status: 'normal',
				industry: "",
				validfrom: 0,
				validto: 0,
				contract_level: "",
				pricing: "",
				primary_insurance: "",
				service: "",
				purpose: "",
				values: "",
				departments: [],
				// settings
				btnColor: "",
				talkNumber: "",
				textNumber: ""
			}
			this.setState({loaded: true, company});
		}
	}

	dateForPicker (dateString: string) {
		return moment(new Date(dateString)).format(UTC_DATE_FORMAT);
	}

	onDismissPopup() {
		this.setState({showSaveMessage: null});
	}

	onFormClose = async (event: any) => {
		// setup
		this.props.close();
	}

	onFormSubmit = async (event: any) => {
		// setup
		event.preventDefault(); // prevent page from reloading
		const {company} = this.state;
		if (!company) {
			return;
		}
		const form = event.currentTarget;

		// Check for changes.
		let changes: NameValuePair[] = [];
		Object.entries(company).forEach(entry => {
			// setup
			const [key, value] = entry;
			let field = form[key];
			if (field) {
				// Determine the value.
				let srcValue;
				const type = field.getAttribute('type');
				if (key === 'census' || key === 'office_census' || key === 'other_census') {
					srcValue = field.value ? parseInt(field.value) : 0;
				} else if (type === 'date') {
					const date = moment.utc(field.value, UTC_DATE_FORMAT);
					srcValue = date.valueOf() / 1000;
				} else if (key === 'talkNumber' || key === 'textNumber') {
					const newValue = field.value.replace('+1').replace(/\D/g,'');
					srcValue  = newValue.replace(/(\d{3})(\d{3})(\d{4})/, '+1-$1-$2-$3');
				} else if (type === 'color') {
					srcValue = field.value.toUpperCase();
				} else {
					srcValue = field.value;
				}

				// See if there is a difference.
				if (srcValue && srcValue != value) {
					changes.push({name: key, value: srcValue});
				}
			}
		});

		// Update the back-end.
		if (!changes.length) {
			this.setState({showSaveMessage: `There is no information to be updated.`, edit: true});
			return;
		}
		const success = await clients.updateClientInfo(company.id, changes);
		if (success) {
			this.setState({showSaveMessage: `${company.name}'s information has been updated.`, edit: true});
		} else if (!success) {
			this.setState({showSaveMessage: `There was a problem updating ${company.name}'s information.`, edit: true});
		}
	}

	uploadFileHandler() {

	}

	render() {
		// setup
		const {edit} = this.state;
		const {loaded, company, showSaveMessage} = this.state;
		if (!loaded || !company) {
			return <></>
		}

		// render
		const canUpdate = true;
		return (
			<div id="ClientForm">
				<Form className="form-popup" onSubmit={this.onFormSubmit}>

					{this.renderFormHeader(company)}
					{this.renderFormBody(company, edit)}
					{this.renderFormFooter(canUpdate, edit)}

				</Form>

				<PopupMessage
					show={!!showSaveMessage}
					title={`${company.name} Information`}
					body={showSaveMessage}
					onClose={this.onDismissPopup}
				/>

			</div>
		);
	}

	renderFormBody(company: CompanyDetails, edit: boolean) {
		// setup
		const startDate = company.validfrom ? moment.utc(company.validfrom * 1000) : moment().utc();
		const strStartDate = startDate.format(UTC_DATE_FORMAT);
		const endDate = company.validto ? moment.utc(company.validto * 1000) : moment().utc();
		const strEndDate = endDate.format(UTC_DATE_FORMAT);

		const body = document.getElementById('ReportsPage-body');
		const style = {"maxHeight": body ? body.clientHeight - 160 : 600};

		// render
		return (
			<div className="form-body scroll-y" style={style}>

				{this.renderBrokerSection(company)}
				{this.renderPurposeSection(company)}

			</div>
		)
	}

	renderBrokerSection(company: CompanyDetails) {
		return (<div className='form-block'>

			<div className='form-column'>
				<div className='form-block-label'>Contact Info:</div>
				<Form.Group controlId="contact_name" className="form-field">
					<Form.Label className="field-md-label">Name:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="text"
						defaultValue={company.contact_name}
					/>
				</Form.Group>
				<Form.Group controlId="contact_phone" className="form-field">
					<Form.Label className="field-md-label">Phone:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="tel"
						defaultValue={company.contact_phone}
					/>
				</Form.Group>
				<Form.Group controlId="contact_email" className="form-field">
					<Form.Label className="field-md-label">Email:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="email"
						defaultValue={company.contact_email}
					/>
				</Form.Group>

				<div className='form-block-label'>Broker Info:</div>
				<Form.Group controlId="broker_name" className="form-field">
					<Form.Label className="field-md-label">Name:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="text"
						defaultValue={company.broker_name}
					/>
				</Form.Group>
				<Form.Group controlId="broker_phone" className="form-field">
					<Form.Label className="field-md-label">Phone:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="tel"
						defaultValue={company.broker_phone}
					/>
				</Form.Group>
				<Form.Group controlId="broker_email" className="form-field">
					<Form.Label className="field-md-label">Email:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="email"
						defaultValue={company.broker_email}
					/>
				</Form.Group>

			</div>

			<div className='form-column'>
				<div className='form-block-label'>Billing Contact:</div>
				<Form.Group controlId="billing_name" className="form-field">
					<Form.Label className="field-md-label">Name:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="text"
						defaultValue={company.billing_name}
					/>
				</Form.Group>
				<Form.Group controlId="billing_phone" className="form-field">
					<Form.Label className="field-md-label">Phone:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="tel"
						defaultValue={company.billing_phone}
					/>
				</Form.Group>
				<Form.Group controlId="billing_email" className="form-field">
					<Form.Label className="field-md-label">Email:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" type="email"
						defaultValue={company.billing_email}
					/>
				</Form.Group>
			</div>
		</div>);
	}

	renderFormFooter(canUpdate: boolean, edit: boolean) {
		return (
			<div className="form-footer">
				<Button className="btn form-btn" variant="forgot" size="sm" onClick={this.onFormClose}>
					Close
				</Button>
				<Button type="submit" className="btn form-btn" size="sm">
					Update
				</Button>
			</div>
		)
	}

	renderFormHeader(company: CompanyDetails) {
		return (
			<div className="form-header">
				{company.name + ' Client Details'}
				<Button id='ClosePopup' variant="forgot" className="btn form-btn" size="sm" onClick={this.onFormClose}>
					X
				</Button>
			</div>
		)
	}

	renderPurposeSection(company: CompanyDetails) {
		const contracts = [ 'Standard', 'Gold', 'Silver', 'Bronze', 'Pay-As-You-Go' ]
			.map(level => {
				const value = level.toLowerCase().replace('-', '_');
				return (level === company.contract_level || value === company.contract_level) ?
					<option value={value} selected>{level}</option> :
					<option value={value}>{level}</option>;
			});

		return (
			<div>

				<div className='form-block-label'>Care Info:</div>
				<div className='form-column'>
					<Form.Group controlId="industry" className="form-field">
						<Form.Label className="field-md-label">Industry:</Form.Label>
						<Form.Control
							className="field-lg-value form-control" type="text"
							defaultValue={company.industry}
						/>
					</Form.Group>
					<Form.Group controlId="contract_level" className="form-field">
						<Form.Label className="field-md-label">Contract Level:</Form.Label>
						<Form.Select className="field-lg-value form-control">
							{contracts}
						</Form.Select>
					</Form.Group>
				</div>
				<div className='form-column'>
					<Form.Group controlId="pricing" className="form-field">
						<Form.Label className="field-md-label">Pricing:</Form.Label>
						<Form.Control
							className="field-lg-value form-control" type="text"
							defaultValue={company.pricing}
						/>
					</Form.Group>
					<Form.Group controlId="primary_insurance" className="form-field">
						<Form.Label className="field-md-label">Primary Ins/EAP:</Form.Label>
						<Form.Control
							className="field-lg-value form-control" type="text"
							defaultValue={company.primary_insurance}
						/>
					</Form.Group>
				</div>

				<div className='form-block-separator'></div>
				<Form.Group controlId="service" className="form-field">
					<Form.Label className="field-md-label">Wellness Service:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" as="textarea"
						defaultValue={company.service}
					/>
				</Form.Group>
				<Form.Group controlId="purpose" className="form-field">
					<Form.Label className="field-md-label">Purpose Statement:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" as="textarea"
						defaultValue={company.purpose}
					/>
				</Form.Group>
				<Form.Group controlId="values" className="form-field">
					<Form.Label className="field-md-label">Core Values:</Form.Label>
					<Form.Control
						className="field-lg-value form-control" as="textarea"
						defaultValue={company.values}
						rows={4}
					/>
				</Form.Group>
			</div>
		);
	}

}

