// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {ActivitySummary, Client, User} from "../../types";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {PopupMessage} from "../../components";

/**
 * SummaryStats
 */

export let companyStats = [
	{ id: "census", name: 'Total Employees', value: 0},
	{ id: "total-download", name: 'Total Downloads', value: 0},
	{ id: "total-hope", name: 'Active Hope Users', value: 0},
	{ id: "total-vest", name: 'Active VEST Users', value: 0},
];

export let engagementStats = [
	{ id: "utilization", name: 'Care Center Utilization', value: 0},
	{ id: "care", name: 'Care Center Engagements', value: 0},
	{ id: "hope", name: 'Hope Opt-In/Opt-Out Activity', value: 0},
	{ id: "norm", name: 'VEST Opt-In/Opt-Out Activity', value: 0},
	{ id: "training", name: 'Live Training Engagements', value: 0},
	{ id: "download", name: 'App Downloads', value: 0},
	{ id: "app", name: 'App Engagements', value: 0},
	{ id: "course", name: 'Courses Viewed', value: 0},
	{ id: "post", name: 'Posts Viewed', value: 0},
	{ id: "story", name: 'Stories Viewed', value: 0},
];

interface SummaryProps {
	user: User,
	client: Client,
	summary: ActivitySummary[],
	chartId: string,
	chartName: string,
	onEditAnalytics: (chartId: string, chartName: string) => void
	onSelectChart: (chartId: string, chartName: string) => void
}
interface SummaryState {
	showInfoMessage: boolean,
	infoTitle: string,
	infoBody: any,
}

export class SummaryStats extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			showInfoMessage: false,
			infoTitle: "Navigating Engagements",
			infoBody: <div>Get engagement details by clicking the list item. Once selected, the 'Engagements' list will show only that summary item. <br/>Revert back to the global view by re-clicking the selected list item.</div>
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onShowPopup = this.onShowPopup.bind(this);
	}

	onDismissPopup() {
		this.setState({showInfoMessage: false});
	}

	onShowPopup() {
		this.setState({showInfoMessage: true});
	}

	render() {
		// setup
		const {showInfoMessage, infoTitle, infoBody} = this.state;

		// render
		return (
			<div id="SummaryStats">
				{this.renderCompanyInfo()}
				<br/>
				{this.renderSummaryInfo()}

				<PopupMessage
					show={showInfoMessage}
					title={infoTitle}
					body={infoBody}
					bg="info"
					onClose={this.onDismissPopup}
				/>
			</div>
		);
	}

	renderCompanyInfo() {
		// setup
		const {summary, client} = this.props;

		// Construct the list.
		const body = companyStats.map(item => {
			// special handling
			const stat = summary.find(s => s.activity_type === item.id);
			let value;
			if (!stat) {
				value = 0;
			} else if (stat && item.id === 'company') {
				value = stat.activity_title;
			} else if (stat) {
				value = stat.total.toLocaleString('en-US');
			}

			// render
			return (
				<tr
					id={item.id}
					key={'summary'+item.id}
				>
					<td className="description">{item.name}</td>
					<td className="value">{value}</td>
				</tr>);
		});

		// render
		return (
			<div>
				<h4 className="title">{client.name}</h4>
				<Table striped bordered responsive>
					<tbody>{body}</tbody>
				</Table>
			</div>
		);
	}

	renderSummaryInfo() {
		// setup
		const {summary, chartId, onSelectChart} = this.props;

		// Construct the list.
		const body = engagementStats.map(item => {
			// special handling
			let value, chart = null;
			const stat = summary.find(s => s.activity_type === item.id);
			if (!stat) {
				value = 0;
			} else if (item.id === 'utilization') {
				value = (Math.ceil(stat.total * 10) / 10) + '%'; // percentage rather than raw number
			} else {
				value = stat.total.toLocaleString('en-US');
			}
			const selected = (item.id === chartId) ? 'selectable selected' : 'selectable';

			// render
			return (
				<tr
					id={item.id}
					key={'summary'+item.id}
					className={selected}
					onClick={() => onSelectChart(item.id, item.name)}
				>
					<td className="description">{item.name}</td>
					<td className="value">{value}</td>
					{chart}
				</tr>);
		});

		// render
		return (
			<div>
				<AiOutlineInfoCircle
					className="info-icon"
					size={24}
					onClick={this.onShowPopup}
				/>
				<h4 className="title">12-Month Engagement Summary</h4>
				<Table striped bordered responsive>
					<tbody>{body}</tbody>
				</Table>
			</div>
		);
	}

}

