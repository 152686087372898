// react
import React from "react";
import {inject, observer} from "mobx-react"

// local
import {
	Footer,
	Header
} from "../components";
import {
	PageProps, system,
	withNavigation
} from "../types";
import {
	LoginForm
} from "../forms";

/**
 * LoginPage
 */

interface LoginProps extends PageProps {
}
interface LoginState {
	passVisible: boolean
}

@inject("system")
@inject("client")
@inject("user")
@observer
class LoginPageComponent extends React.Component<LoginProps, LoginState> {

	constructor(props: LoginProps) {
		super(props);

		// initial state
		this.state = {
			passVisible: false
		}

		// Register the callbacks.
		this.onSubmitLogin = this.onSubmitLogin.bind(this);
	}

	componentDidMount = async () => {
		// See if we have an auto-login.
		const {system, client, user, navigate} = this.props;
		if (system.userid && system.token) {
			const success = await user.authenticate(system.userid, system.token);
			if (success && user.clientId) {
				await client.setClient(user.clientId);
				system.clear();
				if (user.isCompanyManager() || user.isDepartmentManager()) {
					navigate('/reports');
				} else if (user.mdl_id) {
					await user.setStudentInfo(user.mdl_id, 0, 0);
					navigate('/student');
				}
			}
		}
	}

	onSubmitLogin(action: string | null) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to login, if successful.
		if (action === 'student' && user.mdl_id) {
			user.setStudentInfo(user.mdl_id, 0, 0);
			navigate('/student');
		} else if (action === 'reports') {
			navigate('/reports');
		} else if (action === 'forgotpassword' && system.passwordUrl) {
			window.open(system.passwordUrl, '_blank');
		}
	}

	render() {
		// setup
		const {system, user, client, user: {errorLevel, errorMessage}} = this.props;
		const maintenance = false;

		// render
		return (
			<div className="page">
				<Header/>
				{maintenance ?
					<div className='maintenance'>
						This site is under maintenance.<br/>Please check back later.
					</div> :
					<LoginForm
						system={system}
						user={user}
						client={client}
						submit={this.onSubmitLogin}
						errorLevel={errorLevel}
						errorMessage={errorMessage}
					/>
				}
				<Footer/>
			</div>
		);
	}

}

export const LoginPage = withNavigation(LoginPageComponent);
