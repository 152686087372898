// react
import React from "react";
import {BiPieChart} from "react-icons/bi";

// local
import {Client, User} from "../../types";
import {CompanyInsights} from "./CompanyInsights";

/**
 * InsightsSection
 */

interface SectionProps {
	client: Client
	user: User
}


export const InsightsSection = (props: SectionProps) => {

	// setup
	const {client, user} = props;
	const clientReason = client.getCareTotals('company', 'reason');
	const clientWho = client.getCareTotals('company', 'who');
	const allReason = client.getCareTotals('all', 'reason');
	const allWho = client.getCareTotals('all', 'who');

	// render
	if (!user.isCompanyManager()) {
		return null;
	}
	return (
		<div className='section'>
			<div className='section-header'>
				<BiPieChart className="section-icon" size={28}/>
				Care Center Insights
			</div>

			<CompanyInsights
				clientName={client.name}
				clientReason={clientReason}
				clientWho={clientWho}
				allReason={allReason}
				allWho={allWho}
			/>

			<hr/>
		</div>
	)
}

