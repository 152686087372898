// react
import React from 'react';
import {Dropdown} from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {BiLogOut, BiGroup} from "react-icons/bi";
import {AiOutlineUser} from "react-icons/ai";
import {BsPeopleFill, BsFillPersonFill} from "react-icons/bs"
import {RiAdminFill, RiAdminLine, RiLockPasswordLine} from "react-icons/ri"

// local
import {APPMENU_ICON_SIZE, ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_USER, system, User} from "../types";
import {inject, observer} from "mobx-react";

/**
 * AppMenu
 */

type ToggleProps = {
	children?: React.ReactNode;
	onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
	view: string
};

const AppMenuIcon = React.forwardRef((props: ToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
	<div className='menu-icon-wrapper' onClick={(e: any) => {
		e.preventDefault();
		props.onClick(e);
		}}>
		{ props.view === ROLE_ADMIN && <RiAdminFill className="popup-icon" size={45}/>}
		{ props.view === ROLE_COMPANY_MANAGER && <BsPeopleFill className="menu-icon" size={45}/>}
		{ props.view === ROLE_USER && <BsFillPersonFill className="popup-icon" size={45}/>}
		<div className={'down-arrow'}/>
	</div>
));

interface MenuProps {
	user: User
	onLogout: () => void
}
interface MenuState {
}

export class AppMenu extends React.Component<MenuProps, MenuState> {

	constructor(props: MenuProps) {
		super(props);

		this.onSelectItem = this.onSelectItem.bind(this);
	}

	onSelectItem(eventKey: any, event: Object) {
		// setup
		const {user, onLogout} = this.props;

		// Process the selection.
		if (eventKey === 'password' && system.passwordUrl) {
			window.open(system.passwordUrl, '_blank');
		} else if (eventKey === 'logout') {
			onLogout();
		} else {
			user.setView(eventKey);
		}
	}

	render() {
		// setup
		const {user} = this.props;

		// render
		return (
			<div id="AppInfo">
				{this.renderUserInfo(user)}
				{this.renderAppMenu(user)}
			</div>
		);
	}

	renderAppMenu(user: User) {
		// roles
		let roles = [];
		if (user.isAdmin()) {
			roles.push(<Dropdown.Item className='popup-icon-wrapper' eventKey={ROLE_ADMIN}>
				<RiAdminLine className="popup-icon" size={APPMENU_ICON_SIZE}/>
				<div className="popup-icon-text">Admin View</div>
			</Dropdown.Item>);
		} else if (user.isManager()) {
			roles.push(<Dropdown.Item className='popup-icon-wrapper' eventKey={ROLE_COMPANY_MANAGER}>
				<BiGroup className="popup-icon" size={APPMENU_ICON_SIZE}/>
				<div className="popup-icon-text">Manager View</div>
			</Dropdown.Item>);
		} else {
			roles.push(<Dropdown.Item className='popup-icon-wrapper' eventKey={ROLE_USER}>
				<AiOutlineUser className="popup-icon" size={APPMENU_ICON_SIZE}/>
				<div className="popup-icon-text">User View</div>
			</Dropdown.Item>);
		}

		// render
		return (
			<Dropdown id="AppMenu" onSelect={this.onSelectItem}>

				<Dropdown.Toggle as={AppMenuIcon} view={user.view}/>

				<DropdownMenu id="AppPopupMenu">
					{system.passwordUrl && <Dropdown.Item className='popup-icon-wrapper' eventKey="password">
						<RiLockPasswordLine className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Change Password</div>
					</Dropdown.Item>}
					<Dropdown.Item className='popup-icon-wrapper' eventKey="logout">
						<BiLogOut className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Log out</div>
					</Dropdown.Item>
				</DropdownMenu>

			</Dropdown>
		);
	}

	renderUserInfo(user: User) {
		// setup
		return (
			<div id="UserSummary">
				{user.name ? user.name : user.id}
			</div>
		)
	}
}
