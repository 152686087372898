// react
import React from "react";
import {ProgressBar, Table} from "react-bootstrap";
import {LocationSummary} from "../../types";

// local

/**
 * LocationCourses
 */

interface CoursesProps {
	summary: LocationSummary[]
}
interface CoursesState {
}

export class LocationCourses extends React.Component<CoursesProps, CoursesState> {

	render() {
		// setup
		let stats = {
			users: 0,
			total: 0,
			started: 0,
			completed: 0
		};
		const head = this.renderHeader();
		const body = this.renderBody(stats);
		const footer = this.renderFooter(stats);

		// render
		return (
			<div id="LocationCourses">
				<h4 className="title">Course Training by Location</h4>
				<Table id="LocationCourses-Table" striped bordered responsive>
					<thead className="locations">{head}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		);
	}

	renderBody(stats: any) {
		// setup
		const {summary} = this.props;

		// Render the table body.
		let body = [];
		for (let i = 0; i < summary.length; i++) {
			// setup
			const item = summary[i];
			const key = 'location-'+item.id;
			const sPercent = item.courses_total ? Math.ceil(item.courses_started * 100 / item.courses_total) : 0;
			const cPercent = item.courses_total ? Math.ceil(item.courses_completed * 100 / item.courses_total) : 0;

			stats.users += item.users;
			stats.total += item.courses_total;
			stats.started += item.courses_started;
			stats.completed += item.courses_completed;

			// render
			body.push(<tr key={key}>
				<td className="index">{i+1}</td>
				<td className="description">{item.name}</td>
				<td className="value">{item.users.toLocaleString()}</td>
				<td className="value">{item.courses_total.toLocaleString()}</td>
				<td className="value">{item.courses_started.toLocaleString()}</td>
				<td className="percent">
					<ProgressBar now={sPercent} label={sPercent + '%'} />
				</td>
				<td className="value">{item.courses_completed.toLocaleString()}</td>
				<td className="percent">
					<ProgressBar now={cPercent} label={cPercent + '%'} />
				</td>
			</tr>);
		}
		return body;
	}

	renderFooter(stats: any) {
		const sPercent = Math.ceil(stats.started * 100 / stats.total) + '%';
		const cPercent = Math.ceil(stats.completed * 100 / stats.total) + '%';
		return [
			<tr key='location-footer'>
				<td className="index"></td>
				<th className="total">total</th>
				<td className="value">{stats.users.toLocaleString()}</td>
				<td className="value">{stats.total.toLocaleString()}</td>
				<td className="value">{stats.started.toLocaleString()}</td>
				<td className="percent">{sPercent}</td>
				<td className="value">{stats.completed.toLocaleString()}</td>
				<td className="percent">{cPercent}</td>
			</tr>
		]
	}

	renderHeader() {
		return [
			<tr key='location-header'>
				<th className="index">#</th>
				<th className="description">Location</th>
				<th className="value">users</th>
				<th className="value">enrolled</th>
				<th className="value">started</th>
				<th className="percent">%</th>
				<th className="value">finished</th>
				<th className="percent">%</th>
			</tr>
		];
	}

}
