// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {
	CouponInfo,
	CouponUseInfo,
	firstLastToName, timestampToStrDate, User
} from "../../types";
import {NavigateFunction} from "react-router-dom";

/**
 * Coupons Summary
 */

interface CouponProps {
	user: User,
	navigate: NavigateFunction,
	coupons: CouponInfo[],
}
interface CouponState {
}

export class Coupons extends React.Component<CouponProps, CouponState> {

	constructor(props: CouponProps) {
		super(props);

		this.state = {
		}

		// Register the callbacks.
		this.onViewItem = this.onViewItem.bind(this);
	}

	onViewItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to the student page.
		user.setStudentInfo(studentId, orderId, courseId);
		navigate("/student");
	}

	render() {
		// setup
		const {coupons} = this.props;

		// Construct the list.
		const header = [
			<tr key='coupons-use-header'>
				<th className="value">Order</th>
				<th className="description">Student</th>
				<th className="value">Date</th>
				<th className="description">Coupon</th>
				<th className="value">Credit</th>
			</tr>
		];

		const body = [];
		for (let i = 0; i < coupons.length; i++) { // used coupons
			const coupon = coupons[i];
			for (let j = 0; j < coupon.use.length; j++) {
				const item = coupon.use[j];
				const created = timestampToStrDate(item.created, false);
				const name = firstLastToName(item.user_first_name, item.user_last_name);
				const amount = item.coupon_amount.toFixed(2);

				body.push(
					<tr key={'coupon-use-' + coupon.id + '-' + item.id}>
						<td
							className='link'
							onClick={() => this.onViewItem(item.user_id, item.id, 0)}
						>
							{item.id}
						</td>
						<td className='description'>{name}</td>
						<td className='date'>{created}</td>
						<td className='coupon'>{item.coupon_name}</td>
						<td className='value'>{amount}</td>
					</tr>
				);
			}
		}
		for (let i = 0; i < coupons.length; i++) { // unused coupons
			// coupon
			const coupon = coupons[i];
			if (!coupon.use.length) {
				body.push(
					<tr key={'coupon-use-' + coupon.id}>
						<td className='link'/>
						<td className='description'></td>
						<td className='date'>n/a</td>
						<td className='coupon'>{coupon.coupon_name}</td>
						<td className='value'></td>
					</tr>
				);
			}
		}

		// render
		return (
			<div id="CouponsSummary">
				<Table id="CouponsSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
				</Table>
			</div>
		)
	}

}
