// react
import React from 'react';
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {Dropdown} from "react-bootstrap";
import {AiOutlineUsergroupAdd, AiOutlineCloud} from "react-icons/ai";
import {RiGroupLine} from "react-icons/ri";
import {RiListSettingsLine} from "react-icons/ri";
import {BiSupport} from "react-icons/bi";

// local
import {APPMENU_ICON_SIZE, SIDEBAR_ICON_SIZE} from "../types";
import {NavigateFunction} from "react-router-dom";
import {IoBugOutline} from "react-icons/io5";
import {FaProjectDiagram} from "react-icons/fa";
import {MdAddTask} from "react-icons/md";

/**
 * AppMenu
 */

type ToggleProps = {
	children?: React.ReactNode;
	onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

const SupportSettingsIcon = React.forwardRef((props: ToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
	<div className='sidebar-icon-wrapper static' onClick={(e: any) => {
		e.preventDefault();
		props.onClick(e);
		}}>
		<BiSupport className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>
		<div className="sidebar-icon-text">Support</div>
	</div>
));

interface SettingsProps {
	navigate: NavigateFunction,
	clientName: string,
	onShowIssueForm: () => void,
	onShowFeatureForm: () => void,
}
interface SettingsState {
}

export class SupportSettings extends React.Component<SettingsProps, SettingsState> {

	constructor(props: SettingsProps) {
		// setup
		super(props);

		// Register the callbacks.
		this.onSelectItem = this.onSelectItem.bind(this);
	}

	onSelectItem(eventKey: any, event: Object) {
		// setup
		const {onShowIssueForm, onShowFeatureForm, navigate} = this.props;

		if (eventKey === 'add_issue') {
			onShowIssueForm();
		} else if (eventKey === 'add_feature') {
			onShowFeatureForm();
		} else if (eventKey === 'view_issues') {
			navigate('/support');
		}
	}

	render() {
		// render
		return (
			<Dropdown id="SupportSettings" onSelect={this.onSelectItem}>

				<Dropdown.Toggle as={SupportSettingsIcon}/>

				<DropdownMenu id="SupportPopupSettings">
					<Dropdown.Item className='popup-icon-wrapper' eventKey="add_issue">
						<IoBugOutline className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Report an Issue</div>
					</Dropdown.Item>
					<Dropdown.Item className='popup-icon-wrapper' eventKey="add_feature">
						<MdAddTask className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Make a Project Request</div>
					</Dropdown.Item>
					<Dropdown.Item className='popup-icon-wrapper' eventKey="view_issues">
						<RiGroupLine className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Review Issues/Requests</div>
					</Dropdown.Item>
				</DropdownMenu>

			</Dropdown>
		);
	}
}
