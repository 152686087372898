// react
import React from "react";

// local
import {system} from "../types"
import {Button} from "react-bootstrap";

/**
 * NavButton
 */

interface NavProps {
	icon: any,
	text: string,
	className: string,
	onClick: () => void
}

export const NavButton = (props: NavProps) => {

	return (
		<Button
			className={props.className}
			variant='secondary'
			size="sm"
			onClick={() => props.onClick()}
		>
			<div className='nav-icon-wrapper'>
				{props.icon}
			</div>
			{props.text}
		</Button>
	);
}

