// react
import React from 'react';
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {Dropdown} from "react-bootstrap";
import {FiSettings} from "react-icons/fi";
import {RiListSettingsLine} from "react-icons/ri";
import {DropdownHr} from "./DropdownHr";
import {NavigateFunction} from "react-router-dom";
import {IoBugOutline} from "react-icons/io5";
import {MdAddTask, MdSettings} from "react-icons/md";
import {FaTasks} from "react-icons/fa";

// local
import {APPMENU_ICON_SIZE, Client, SIDEBAR_ICON_SIZE} from "../types";

/**
 * AppMenu
 */

type ToggleProps = {
	children?: React.ReactNode;
	onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

const AppSettingsIcon = React.forwardRef((props: ToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
	<div className='sidebar-icon-wrapper static' onClick={(e: any) => {
		e.preventDefault();
		props.onClick(e);
		}}>
		<FiSettings className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>
		<div className="sidebar-icon-text">Settings</div>
	</div>
));

interface SettingsProps {
	navigate: NavigateFunction,
	client: Client,
	onEditClientDetails: () => void,
	onEditClientInfo: () => void,
	onShowIssueForm: () => void,
	onShowFeatureForm: () => void,
}
interface SettingsState {
}

export class AppSettings extends React.Component<SettingsProps, SettingsState> {

	constructor(props: SettingsProps) {
		super(props);

		this.onSelectItem = this.onSelectItem.bind(this);
	}

	onSelectItem(eventKey: any, event: Object) {
		// setup
		const {onEditClientDetails, onEditClientInfo, onShowFeatureForm, onShowIssueForm, navigate} = this.props;

		// Process the selection.
		if (eventKey === 'edit_client_info') {
			onEditClientInfo();
		} else if (eventKey === 'edit_client_details') {
			onEditClientDetails();
		} else if (eventKey === 'add_issue') {
			onShowIssueForm();
		} else if (eventKey === 'add_feature') {
			onShowFeatureForm();
		} else if (eventKey === 'view_issues') {
			navigate('/support');
		}
	}

	render() {
		// setup
		const {client} = this.props;

		// render
		return (
			<Dropdown id="AppSettings" onSelect={this.onSelectItem}>

				<Dropdown.Toggle as={AppSettingsIcon}/>

				<DropdownMenu id="AppPopupSettings">

					{/* support */}
					<Dropdown.Item className='popup-icon-wrapper' eventKey="add_issue">
						<IoBugOutline className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Report an Issue</div>
					</Dropdown.Item>
					<Dropdown.Item className='popup-icon-wrapper section' eventKey="add_feature">
						<MdAddTask className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Make a Project Request</div>
					</Dropdown.Item>
					<Dropdown.Item className='popup-icon-wrapper' eventKey="view_issues">
						<FaTasks className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Review Issues/Requests</div>
					</Dropdown.Item>
					<DropdownHr/>

					{/* clients */}
					<Dropdown.Item className='popup-icon-wrapper' eventKey="edit_client_info">
						<MdSettings className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Edit Client Info</div>
					</Dropdown.Item>
					<Dropdown.Item className='popup-icon-wrapper' eventKey="edit_client_details">
						<RiListSettingsLine className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Edit Client Details</div>
					</Dropdown.Item>

				</DropdownMenu>

			</Dropdown>
		);
	}
}
